.RealTimeMeetingContainer {
  .meetingItemScroll {
    height: 397px;
    overflow: auto;
    margin-top: 15px;
    @media (max-width: 767px) {
      height: 305px;
    }
  }

  .SpeakerBtn {
    &.show {
      &::before {
        // display: none;
        content: "";
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgb(0 0 0 / 6%);
        z-index: 102;
      }
    }
    .dropdown-menu {
      min-width: 200px !important;
      left: 0 !important;
      padding: 10px 20px;
      .checkbox-container {
        &.active {
          background-color: transparent;
        }
      }
    }
    .dropdwon-scroll {
      height: 80px;
      margin-top: 0;
      padding-right: 0;
    }
    .checkbox-container {
      padding: 12px 0px 11px;
      line-height: 17px;
      .checkmark {
        right: 4px;
      }
    }
    .dropdown-toggle {
      width: 180px !important;
      height: 50px !important;
      color: #1b1726 !important;
      font-family: "Pretendard-Regular" !important;
      font-size: 14px;
      font-weight: 400 !important;
      background: #ffffff !important;
      border: 1px solid rgba(82, 98, 108, 0.1) !important;
      &::after {
        display: block !important;
        top: 13px !important;
      }
      &::before {
        display: none !important;
      }
      @media (max-width: 767px) {
        width: 245px!important;
      }
    }
  } //speakerbtn

  h1 {
    align-items: flex-end;
    span {
      &:first-child {
        @media (max-width: 767px) {
          max-width: 220px;
        }
      }
    }
    .btnDemo {
      top: -6px;
      position: relative;
      @media (max-width: 767px) {
        top: -3px;
      }
    }
  }
  .realTimeMeeting {
    .empty-box-image {
      display: flex;
      margin-left: -10px;
      margin-right: -10px;
      @media (max-width: 767px) {
        flex-direction: column;
        align-items: center;
      }
      .col-50 {
        width: 50%;
        max-width: 50%;
        padding-left: 10px;
        padding-right: 10px;
        @media (max-width: 767px) {
          width: 100%;
          max-width: 100%;
          &.img2 {
            margin-top: 15px;
          }
        }
        img {
          max-width: 100%;
        }
      }
    }
    .box-empty-state {
      margin-top: 75px;
      margin-bottom: 76px;
      @media (max-width: 767px) {
        margin-top: 0px;
        margin-bottom: 0px;
      }
      &.StartYourConversation {
        margin-top: 0;
        margin-bottom: 0;
        .title-card {
          font-weight: 600;
          font-family: "Pretendard-SemiBold";
          font-size: 16px;
          line-height: 19px;
          color: #1b1726;
          margin-top: 0;
        }
        .DropdownWithOverLay {
          .dropdown-toggle {
            border-width: 0;
            background: rgb(82 98 108 / 5%);
            width: 107px;
            &::before {
              background: url("../../assets/images/ic-setting-grey.svg");
              width: 16px;
              height: 16px;
              background-repeat: no-repeat;
              content: "";
              background-size: 100% 100%;
              background-position: center center;
              margin-left: 0;
              margin-right: 5px;
              justify-content: flex-start !important;
              padding-left: 10px;
            }
            &::after {
              display: none;
            }
          }
          .dropdown-menu {
            min-width: 580px;
            padding: 20px;
            @media (max-width: 767px) {
              min-width: 330px;
              padding: 20px 10px 20px 20px;
            }
          }
        }
        .SettingsBtnDropDown {
          .members-tbody-scroll {
            height: auto;
            padding-right: 10px;
            @media (min-width: 768px) {
              width: 100%;
              
            }
          }
          .membersLanguageModelField {
            .DropdownBackdrop {
              opacity: 0;
            }
          }

          .dropdown-header {
            margin-top: 0;
            margin-bottom: 30px;
          }
          .members-table {
            max-width: 100%;
          }
          .dropdDownFooter {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            margin-top: 15px;
          }
          .DiscardBtn {
            height: 30px;
            width: 94px;
            background: rgb(82 98 108 / 5%);
            border-radius: 5px;
            font-size: 12px;
            font-weight: 600;
            font-family: "Pretendard-SemiBold";
            border-width: 0;
            font-style: normal;
            line-height: 15px;
            text-align: center;
            text-transform: uppercase;
            color: #52626c;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 10px;
            &.disabled-btn {
              color: rgba(82, 98, 108, 0.5) !important;
            }
          }
          .ApplyBtn {
            height: 30px;
            width: 91px;
            background: #25adfc;
            border-radius: 5px;
            font-size: 12px;
            font-weight: 700;
            font-family: "Pretendard-Bold";
            border-width: 0;
            font-style: normal;
            line-height: 15px;
            text-align: center;
            text-transform: uppercase;
            color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
        .ExitMeetingBtn {
          height: 30px;
          width: 126px;
          background: rgb(82 98 108 / 5%);
          border-radius: 5px;
          font-size: 12px;
          font-weight: 700;
          font-family: "Pretendard-Bold";
          border-width: 0;
          font-style: normal;
          line-height: 15px;
          text-align: center;
          text-transform: uppercase;
          color: #52626c;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .card-header,
        .card-footer {
          width: 100%;
        }
        .card-footer {
          justify-content: space-between !important;
          @media (max-width: 767px) {
            margin-top: 20px;
          }
        }

        .MuteAllBtn {
          height: 30px;
          min-width: 107px;
          background: rgb(37 173 252 / 5%);
          border-radius: 5px;
          font-size: 12px;
          font-weight: 700;
          font-family: "Pretendard-Bold";
          border-width: 0;
          font-style: normal;
          line-height: 15px;
          text-align: center;
          text-transform: uppercase;
          color: #25adfc;
          display: flex;
          align-items: center;
          justify-content: center;
          .ic-txt {
            margin-left: 5px;
          }

          .icon {
            img {
              width: 12px;
            }
          }
        }
        .placeholderBox {
          margin-top: 150px;
          margin-bottom: 143px;
          @media (max-width: 767px) {
            margin-top: 177px;
            margin-bottom: 176px;
          }
          .ImageBox {
            text-align: center;
          }
        }
      }
      &.MemberSettingsBox {
        margin-top: 24px;
        margin-bottom: 14px;
        @media (max-width: 767px) {
          margin-top: 20px;
          margin-bottom: 0px;
        }
      }
      .empty-title {
        margin-top: 25px;
        margin-bottom: 10px;
      }
      .empty-txt {
        max-width: 400px;
      }
      .call-to-action-wrap {
        margin-top: 30px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        @media (max-width: 767px) {
          margin-top: 40px;
        }
        .ContinueBtn {
          height: 40px;
          max-width: 400px;
          width: 100%;
          background: #25adfc;
          box-shadow: 2px 5px 15px rgba(0, 0, 0, 0.05);
          border-radius: 5px;
          font-size: 13px;
          font-weight: 800;
          font-family: "Pretendard-ExtraBold";
          border-width: 0;
          font-style: normal;
          line-height: 16px;
          text-align: center;
          text-transform: uppercase;
          color: #ffffff;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      &.MicrophoneSettingsBox {
        margin-top: 53px;
        margin-bottom: 43px;
        @media (max-width: 767px) {
          margin-top: 20px;
          margin-bottom: 0px;
        }
        .ImageBox {
          .outlineBox {
            img {
              width: 21px;
              height: 21px;
            }
          }
        }
        .empty-txt {
          margin-bottom: 40px;
          @media (max-width: 767px) {
            margin-bottom: 35px;
          }
        }
        .empty-title {
          margin-top: 20px;
          margin-bottom: 5px;
        }
        .call-to-action-wrap {
          margin-top: 5px;
          @media (max-width: 767px) {
            margin-top: 5px;
          }
        }
      }
      &.MemberSettingsBox {
        .empty-title {
          margin-top: 20px;
          margin-bottom: 30px;
        }
      }
    }
  }
  .customFormUI {
    max-width: 400px;
    width: 100%;
    margin: 0 auto;
  }
} //RealTimeMeetingContainer

.outlineBox {
  background: rgb(37 173 252 / 7%);
  box-shadow: 2px 5px 15px rgb(0 0 0 / 5%);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  &.size45 {
    width: 45px;
    height: 45px;
  }
  &.size45 {
    width: 65px;
    height: 65px;
    box-shadow: 2px 5px 15px rgba(0, 0, 0, 0);
  }
}

.members-table {
  max-width: 500px;
  width: 100%;
  margin: 0 auto;
  .members-thead {
    display: flex;
    .membersCol {
      font-style: normal;
      font-weight: 700;
      font-family: "Pretendard-Bold";
      font-size: 10px;
      line-height: 12px;
      text-transform: uppercase;
      color: #52626c;
      margin-bottom: 10px;
      padding-left: 15px;
      padding-right: 15px;
    }
    .membersColEmpty {
      width: 45px;
    }
    .membersSpeakerName {
      width: 170px;
      @media (max-width: 767px) {
        display: none;
      }
    }
    .membersLanguageModel {
      width: 180px;
      margin-left: 15px;
      margin-right: 40px;
      @media (max-width: 767px) {
        display: none;
      }
    }
    .membersActions {
      padding-left: 0;
      padding-right: 0;
      @media (max-width: 767px) {
        display: none;
      }
    }
  }
  .members-tbody {
    .members-thead {
      display: none;
      @media (max-width: 767px) {
        display: block;
        > div {
          display: block;
          margin-bottom: 10px;
        }
      }
    }
    .members-tbody-scroll {
      margin-top: 10px;
      overflow: auto;
      max-height: 250px;
      padding-bottom: 60px;
      @media (min-width: 768px) {
        width: calc(100% + 32px);
      }
    }
    .membersRow {
      display: flex;
      margin-bottom: 15px;
      align-items: center;
      // position: relative;
      // z-index: 1;
      @media (max-width: 767px) {
        margin-bottom: 30px;
        flex-wrap: wrap;
        align-items: flex-end;
        position: relative;
      }
      &:last-child {
        margin-bottom: 0;
        @media (max-width: 767px) {
          margin-bottom: -10px;
        }
      }
      .membersActionsItems {
        @media (max-width: 767px) {
          position: absolute;
          top: 36px;
          right: 24px;
          z-index: 2;
        }
      }

      .withICon {
        margin-right: 15px;
        @media (max-width: 767px) {
          height: 50px;
          align-items: center;
          justify-content: center;
          display: flex;
        }
      }
      .form-control {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: #1b1726;
        background: rgb(255 255 255 / 10%);
        border: 1px solid rgb(82 98 108 / 10%);
        border-radius: 5px;
        padding: 6px 15px;
        height: 50px;
      }
      .membersSpeakerNameField {
        width: 170px;
        height: 50px;
        .form-control {
          &:focus {
            box-shadow: none;
          }
        }
        @media (max-width: 767px) {
          height: auto;
          width: 180px;
        }
      }
      .membersLanguageModelField {
        width: 180px;
        height: 50px;
        margin-left: 15px;
        margin-right: 40px;
        @media (max-width: 767px) {
          width: calc(100% - 15px);
          height: auto;
          padding-left: 15px;
          margin-top: 20px;
          margin-right: 0;
          .membersLanguageModel {
            // padding-left: 0;
          }
          > div {
            padding-left: 15px;
          }
        }
        .dropdown-menu {
          height: 100px;
          position: absolute;
          inset: 0 !important;
          transform: translate(0, 0) !important;
          top: -30px !important;
        }
      }
      .action-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 46px;
        .mic-icon {
          cursor: pointer;
          @media (max-width: 767px) {
            background-color: #ffffff;
          }
        }
        .view-members {
          cursor: pointer;
        }
      }
    }
  }
}

.meeting-items {
  width: 100%;
  .itemRow {
    background: rgb(82 98 108 / 5%);
    border-radius: 5px;
    position: relative;
    border-left-width: 7px;
    border-left-color: transparent;
    border-left-style: solid;
    margin-top: 15px;
    padding: 20px 15px 20px 23px;
    &:first-child {
      margin-top: 0px;
    }
    @media (max-width: 767px) {
      padding: 20px;
      border-left-width: 2px;
    }
    &.firstItem {
      border-left-color: #ff6352;
    }
    &.firstSecond {
      border-left-color: #ff8a00;
    }
    &.firstThree {
      border-left-color: #ffc700;
    }
    .itemHeader {
      line-height: 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      @media (max-width: 767px) {
        flex-direction: column;
        align-items: flex-start;
      }
      .title {
        font-style: normal;
        font-weight: 600;
        font-family: "Pretendard-SemiBold";
        font-size: 13px;
        line-height: 16px;
        color: #1b1726;
        display: block;
        @media (max-width: 767px) {
          margin-bottom: 10px;
        }
      }
      .updated-time {
        display: flex;
        align-items: center;
        .timeIcon {
          margin-right: 5px;
        }
        .updaterd-time-txt {
          font-style: normal;
          font-weight: 400;
          font-size: 11px;
          line-height: 13px;
          color: #1b1726;
        }
      }
    }
    .itemBody {
      .loader-wrap {
        margin-top: 10px;
        margin-left: 10px;
      }
      .itemBodyTxt {
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 26px;
        color: #1b1726;
        max-width: 995px;
        width: 100%;
        margin: 10px 0 0;
        @media (max-width: 767px) {
          margin-top: 15px;
        }
      }
    }
  }
}

.selectedMicContainer {
  position: relative;
  display: block;
  z-index: 1;
  .micCount {
    position: absolute;
    width: 30px;
    height: 30px;
    left: 0;
    top: -5px;
    z-index: 2;
    font-family: "Pretendard-Black";
    font-style: normal;
    font-weight: 900;
    font-size: 10px;
    line-height: 12px;
    display: flex;
    align-items: center;
    color: #ffffff;
    opacity: 0.9;
    text-align: center;
    justify-content: center;
  }
}

.customSelectDropDown {
  margin-bottom: 20px;
  border: 1px solid rgba(82, 98, 108, 0.1) !important;
  border-radius: 5px;
  position: relative;
  &.SpeakerBtn {
    .dropdown-toggle {
      width: 100% !important;
      height: 70px !important;
      color: #1b1726 !important;
      font-family: "Pretendard-SemiBold" !important;
      font-size: 14px;
      font-weight: 600 !important;
      padding: 36px 20px 16px !important;
      text-transform: none !important;
      border: 0px solid rgba(82, 98, 108, 0.1) !important;
      border-radius: 0 !important;
      position: static;
      max-width: calc(100% - 24px);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: block !important;
      text-align: left;
      &::after {
        top: 26px !important;
        right: 20px !important;
      }
      &::before {
        content: "";
        display: block !important;
        font-family: "Pretendard-Medium";
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        color: #52626c;
        position: absolute;
        top: 16px;
        left: 20px;
        z-index: 1;
      }
    }
    .dropdwon-scroll {
      min-height: 80px;
      height: auto;
      max-height: 200px;
      overflow: auto;
      margin-right: -20px;
    }
  }
  &.choose-mic {
    .dropdown-toggle {
      &::before {
        content: "Number of microphones";
      }
    }
  }
  &.default-language-model {
    .dropdown-toggle {
      &::before {
        content: "Default language model";
      }
    }
  }
} //customSelectDropDown
