.dots-5 {
  width: 8px;
  aspect-ratio: 1;
  border-radius: 50%;
  animation: d5 1s infinite linear alternate;
}
@keyframes d5 {
  0% {
    box-shadow: 16px 0 #25ADFC, -16px 0 #0002;
    background: #25ADFC;
  }
  33% {
    box-shadow: 16px 0 #25ADFC, -16px 0 #25ADFC;
    background: #25ADFC;
  }
  66% {
    box-shadow: 16px 0 #0002, -16px 0 #25ADFC;
    background: #0002;
  }
  100% {
    box-shadow: 16px 0 #0002, -16px 0 #25ADFC;
    background: #25ADFC;
  }
}
