.VideoContentContainer {
  h1 {
    align-items: center;
    @media (max-width: 767px) {
      align-items: flex-end;
    }
    span {
      &:first-child {
        @media (max-width: 767px) {
          max-width: 205px;
        }
      }
    }
    .btnDemo {
      @media (max-width: 767px) {
        top: -3px;
        position: relative;
      }
    }
  }
  // Search Start
  .searchingCard {
    .searching {
      padding-top: 28px;
      .loaderWrap {
        p {
          margin-top: 20px;
        }
      }
      * {
        scrollbar-width: 0 !important;
        scrollbar-color: rgb(82 98 108 / 0%) #ffffff !important;
      }

      *::-webkit-scrollbar {
        width: 0 !important;
        height: 0 !important;
      }

      *::-webkit-scrollbar-track {
        background: transparent !important;
      }

      *::-webkit-scrollbar-thumb {
        background-color: rgb(82 98 108 / 0%);
        border-radius: 0;
        border: 0 solid #000;
      }
      @media (max-width: 991px) {
        padding-top: 24px;
      }
    }
    .searchExampleHeader {
      display: flex;
      align-items: center;
      margin-top: 25px;
      margin-bottom: 40px;
      @media (max-width: 1199px) {
        flex-wrap: wrap;
        align-items: flex-start;
        margin-top: 20px;
        margin-bottom: 35px;
        // overflow: auto;
      }
      .example-txt {
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 16px;
        color: #52626c;
        white-space: nowrap;
        @media (max-width: 1199px) {
          margin-bottom: 10px;
          width: 100%;
        }
      }
      .example-types {
        display: flex;
        align-items: center;
        white-space: nowrap;
        overflow: auto;
        margin-right: -20px;
        .type {
          padding: 6px 10px;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          height: 25px;
          background: rgb(82 98 108 / 5%);
          border-radius: 100px;
          font-style: normal;
          font-weight: 700;
          font-family: "Pretendard-Bold";
          font-size: 11px;
          line-height: 13px;
          color: #52626c;
          margin-left: 10px;
          cursor: pointer;
          @media (max-width: 1199px) {
            &:first-child {
              margin-left: 0;
            }
          }
          &.active {
            background: rgb(37 173 252 / 5%);
            color: #25adfc;
          }
        }
      }
    }
    .search-empty-state {
      display: flex;
      flex-direction: column;
      text-align: center;
      margin-bottom: 92px;
      margin-top: 81px;
      @media (max-width: 767px) {
        margin-bottom: 29px;
        margin-top: 29px;
      }
      img {
        margin: 0 auto;
      }
      .title {
        margin-top: 8px;
        margin-bottom: 6px;
        font-style: normal;
        font-weight: 600;
        font-family: "Pretendard-SemiBold";
        font-size: 16px;
        line-height: 19px;
        color: #1b1726;
      }
      .search-txt {
        margin: 0 auto;
        max-width: 350px;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 25px;
        text-align: center;
        color: #52626c;
      }
    }
    .searchBox {
      background-color: rgb(82 98 108 / 5%);
      padding: 15px 20px 15px 21px;
      display: flex;
      align-content: center;
      border-radius: 5px;
      @media (max-width: 767px) {
        padding: 5px 5px 5px 0;
        // background-color: transparent;
      }
      form {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        @media (max-width: 767px) {
          // flex-direction: column;
        }
        .searchIcon {
          font-size: 0;
          @media (max-width: 767px) {
            display: none;
          }
        }
        .search-input-wrap {
          display: flex;
          align-items: center;
          width: 100%;
          padding-right: 24px;
          @media (max-width: 767px) {
            padding-right: 0;
            // padding: 19px 20px;
            // background-color: rgb(82 98 108 / 5%);
          }
          .searchInput {
            width: 100%;
          }
          .form-control {
            padding: 0 16px;
            border-width: 0;
            font-style: normal;
            font-weight: 600;
            font-family: "Pretendard-SemiBold";
            font-size: 16px;
            line-height: 19px;
            color: #52626c;
            background-color: transparent;
            height: auto;
            @media (max-width: 767px) {
              line-height: 22px;
              padding: 0 15px;
              font-size: 14px;
            }

            &:focus {
              box-shadow: none;
            }
            &::placeholder {
              color: #52626c;
            }
          }
        }
        .searchBtn {
          margin-left: auto;
          @media (max-width: 767px) {
            // width: 100%;
            margin-top: 0;
          }
        }
      }
      .searchBtn {
        @media (max-width: 767px) {
          // display: none;
        }
        .btnSearch {
          border-width: 0;
          font-style: normal;
          font-weight: 800;
          font-family: "Pretendard-ExtraBold";
          font-size: 13px;
          line-height: 16px;
          text-transform: uppercase;
          color: #ffffff;
          width: 120px;
          height: 40px;
          background: #25adfc;
          border-radius: 5px;
          display: flex;
          align-items: center;
          justify-content: center;
          @media (max-width: 767px) {
            width: 50px;
            height: 50px;
            .Icontxt {
              display: none;
            }
          }
          .Icon {
            display: none;
            @media (max-width: 767px) {
              display: block;
            }
          }
        }
      }
    }
  }
  // Search End
  .LoadMoreBtn {
    background: rgb(37 173 252 / 10%);
    width: 132px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    font-weight: 700;
    font-family: "Pretendard-Bold";
    font-size: 12px;
    line-height: 15px;
    text-transform: uppercase;
    color: #25adfc;
    border-width: 0;
    margin: 15px auto;
    &:hover,
    &:focus,
    &.active,
    &:active {
      background: rgb(37 173 252 / 10%) !important;
      color: #25adfc !important;
    }
  }
}

.searchCardRow {
  margin-left: -15px;
  margin-right: -15px;
  display: flex;
  flex-wrap: wrap;
  @media (max-width: 767px) {
    margin-top: 0;
  }
}
.searchingCardView {
  padding-right: 15px;
  padding-left: 15px;
  @media (max-width: 767px) {
    margin-bottom: 20px;
  }
  .cardContent {
    padding: 20px 0px 23px 20px;
    line-height: normal;
    @media (max-width: 767px) {
      padding-bottom: 22px;
    }
    .contentScroll {
      height: 137px;
      overflow: auto;
      @media (max-width: 767px) {
        height: 137px;
      }
    }
    .cardTitle {
      display: block;
      margin-bottom: 5px;
      font-style: normal;
      font-weight: 600;
      font-family: "Pretendard-SemiBold";
      font-size: 15px;
      line-height: 24px;
      color: #1b1726;
      padding-right: 24px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .list-unstyled {
      padding-right: 35px;
      .list-item {
        margin-top: 15px;
        display: flex;
        justify-content: space-between;
        @media (max-width: 767px) {
          flex-direction: column;
        }
      }
      .left-item {
        font-style: normal;
        font-weight: 500;
        font-family: "Pretendard-Medium";
        font-size: 12px;
        line-height: 15px;
        color: #25adfc;
        span {
          cursor: pointer;
        }
      }
      .right-item {
        text-align: right;
        @media (max-width: 767px) {
          text-align: left;
          margin-top: 5px;
        }
        .txt {
          font-style: normal;
          font-weight: 500;
          font-family: "Pretendard-Medium";
          font-size: 12px;
          line-height: 15px;
          color: #52626c;
          display: block;
          max-width: 200px;
          @media (max-width: 767px) {
            max-width: 100%;
          }
          .highlight {
            background: rgb(37 173 252 / 10%);
          }
        }
        .txt-important-factor {
          max-width: 200px;
          display: block;
          font-style: normal;
          font-weight: 500;
          font-family: "Pretendard-Medium";
          font-size: 10px;
          line-height: 12px;
          text-align: right;
          color: rgb(255 76 76 / 50%);
          @media (max-width: 767px) {
            max-width: 100%;
            text-align: left;
          }
        }
      }
    }
  }
  .card {
    border-width: 0;
    background: hwb(203deg 32% 58% / 3%);
    border-radius: 5px;
    padding: 0;
    box-shadow: none;
    width: 100%;
    .thumbnailImgMedia {
      position: relative;
      z-index: 2;
      cursor: pointer;
      .playIcon {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        width: 50px;
        height: 50px;
        z-index: 1;
      }
      .thumbnailImg {
        object-fit: cover;
        max-width: 100%;
        border-radius: 5px;
        width: 100%;
        height: 210px;
      }
    }
  }
}
.loadMoreContainer {
  margin: 20px auto 0;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 240px;
  width: 100%;
  height: 40px;
  background: #ffffff;
  border: 1px solid rgba(82, 98, 108, 0.1);
  box-shadow: 2px 5px 15px rgba(0, 0, 0, 0.05);
  border-radius: 100px;
  cursor: pointer;
  position: relative;
  .loadingContent {
    font-style: normal;
    font-weight: 600;
    font-family: "Pretendard-SemiBold";
    font-size: 15px;
    line-height: 24px;
    color: #52626c;
    margin-right: 5px;
  }
  .loaderContainer {
    padding-left: 12px;
    margin-left: 8px;
    display: inline-flex;
    align-items: center;
    position: relative;
  }
}

.VidoeYTModal {
  .btn-close {
    width: 20px;
    height: 20px;
    color: #52626c;
    border-radius: 0;
    margin: 0;
    position: absolute;
    top: 32px;
    right: 30px;
    padding: 0;
    font-size: 0;
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
    @media (max-width: 1200px) {
      right: 16px;
      top: 16px;
    }
    &:focus {
      outline: 0;
      box-shadow: none;
    }
    &::before {
      display: block;
      background: url("../../assets/images/ic-modal-close.svg");
      width: 11px;
      height: 11px;
      background-repeat: no-repeat;
      content: "";
      background-size: 100% 100%;
      background-position: center center;
    }
    // .a{
    //   ic-modal-close.svg
    // }
  }
  .rows {
    display: flex;
    background-color: #fff;
    border-radius: 8px;
    iframe{
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }
    @media (max-width: 1200px) {
      flex-direction: column;
    }
  }
  .col-mds-8 {
    max-width: 710px;
    width: 100%;
    font-size: 0;
    @media (max-width: 1200px) {
      max-width: 100%;
      width: 100% !important;
    }
    > div {
      width: 100% !important;
      height: 100% !important;
      max-width: 710px;
      max-height: 550px;
      @media (max-width: 1200px) {
        max-width: 100%;
        width: 100% !important;
        height: 33vh !important;
      }
      @media (max-width: 767px) {
        height: 25vh !important;
      }
    }
  }
  .col-mds-4 {
    max-width: 410px;
    width: 100%;
    @media (max-width: 1200px) {
      max-width: 100%;
    }
  }
  .modal-dialog {
    @media (min-width: 576px) {
      max-width: 1120px;
    }
    .modal-content {
      background-color: transparent;
      > div {
        margin: 0 auto !important;
        width: 100%;
        @media (max-width: 767px) {
          width: 95vw !important;
          height: auto !important;
        }
        @media (min-width: 1200px) {
          width: 100% !important;
          height: 100vh !important;
          max-height: 550px !important;
        }
      }
    }
  }
  .video-time-laps {
    background-color: #fff;
    max-width: 410px;
    width: 100%;
    padding: 32px 0 32px 32px;
    max-height: 550px;
    height: 100%;
    display: flex;
    flex-direction: column;
    border-bottom-right-radius: 8px;
    border-top-right-radius: 8px;
    @media (min-width: 1201px) {
      position: relative;
    }
    @media (max-width: 1200px) {
      max-width: 100%;
    }
    @media (max-width: 767px) {
      padding: 24px 15px 24px 15px;
    }
    .modal-header {
      padding: 0;
      border-width: 0;
      border-radius: 0;
      font-family: "Pretendard-SemiBold";
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      line-height: 18px;
      color: #1b1726;
      padding-right: 64px;
      @media (max-width: 767px) {
        padding-right: 0;
      }
    }
  }
  .list-unstyled {
    height: 100%;
    max-height: 452px;
    overflow: auto;
    margin-top: 16px;
    margin-bottom: 0;
    padding-right: 24px;
    @media (max-width: 1200px) {
      max-height: 152px;
    }
    @media (max-width: 767px) {
      padding-right: 15px;
    }
    .list-item {
      margin-top: 15px;
      display: flex;
      justify-content: space-between;
      @media (max-width: 767px) {
        flex-direction: column;
      }
    }
    .left-item {
      font-style: normal;
      font-weight: 500;
      font-family: "Pretendard-Medium";
      font-size: 12px;
      line-height: 15px;
      color: #25adfc;
      span {
        cursor: pointer;
      }
    }
    .right-item {
      text-align: right;
      @media (max-width: 767px) {
        text-align: left;
        margin-top: 5px;
      }
      .txt {
        font-style: normal;
        font-weight: 500;
        font-family: "Pretendard-Medium";
        font-size: 12px;
        line-height: 15px;
        color: #52626c;
        display: block;
        max-width: 200px;
        @media (max-width: 767px) {
          max-width: 100%;
        }
        .highlight {
          background: rgb(37 173 252 / 10%);
        }
      }
      .txt-important-factor {
        max-width: 200px;
        display: block;
        font-style: normal;
        font-weight: 500;
        font-family: "Pretendard-Medium";
        font-size: 10px;
        line-height: 12px;
        text-align: right;
        color: rgb(255 76 76 / 50%);
        @media (max-width: 767px) {
          max-width: 100%;
          text-align: left;
        }
      }
    }
  }
} //VidoeYTModal
