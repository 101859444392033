.VoiceAuthenticationContainer {

  h1 {
    align-items: flex-end;

    span {
      &:first-child {
        @media (max-width: 767px) {
          max-width: 203px;
        }
      }
    }

    .btnDemo {
      top: -6px;
      position: relative;
    }
  }
  .heading-txt {
    max-width: 1100px;
  }
  .uploaded-file-row--error{
    margin: 0 30px 30px;
    @media (max-width: 991px) {
      margin: 0 20px 20px;
    }
  }
}

.d-none {
  display: none !important;
}

.VoiceAuthentication {
  padding: 0 !important;
  @media (min-width: 992px) {
    // height: 400px;
  }

  .card-header {
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    background: rgb(37 173 252 / 5%);
    padding: 0 40px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0;

    @media (max-width: 767px) {
      padding: 0 20px;
    }

    .list-unstyled {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: center;
      margin-bottom: 0;

      .list-item {
        width: 50%;
        text-align: center;
        justify-content: center;

        &.list-item-tab {
          z-index: 1;
          position: relative;
          font-style: normal;
          font-weight: 700;
          font-family: "Pretendard-Bold";
          font-size: 12px;
          line-height: 15px;
          display: flex;
          align-items: center;
          text-align: center;
          text-transform: uppercase;
          color: rgb(82 98 108 / 50%);
          cursor: pointer;
          min-height: 17px;
          @media (max-width: 767px) {
            font-size: 11px;
            line-height: 13px;
            justify-content: flex-end;
            &:first-child {
              justify-content: flex-start;
            }
          }

          .content-wrap {
            @media (max-width: 767px) {
              display: flex;
              flex-direction: column;
              align-items: flex-start;
            }
          }

          &::after {
            content: "";
            position: absolute;
            width: calc(100% + 1px);
            height: 2px;
            background: #25adfc;
            border-radius: 100px;
            opacity: 0;
            bottom: -16px;
            left: 0;
            @media (max-width: 767px) {
              bottom: -12px;
            }
          }

          &.active {
            color: #25adfc;

            &::after {
              opacity: 1;
            }

            .stepComletedIcon {
              display: none;
            }
          }

          &.step-completed {
            color: #04d9b2;

            .stepComletedIcon {
              display: block;
              margin-right: 10px;
              @media (max-width: 767px) {
                margin-right: 4px;
              }
            }
          }
        }
      }
    }
  }

  .box-space {
    .box-pd {
      padding: 30px;

      @media (max-width: 991px) {
        padding: 20px;
      }
    }

    .box-title {
      font-style: normal;
      font-weight: 600;
      font-family: "Pretendard-SemiBold";
      font-size: 16px;
      line-height: 19px;
      color: #1b1726;
      display: block;
    }

    .box-txt {
      margin-top: 6px;
      margin-bottom: -5px;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: #52626c;

      @media (max-width: 767px) {
        font-size: 12px;
        line-height: 24px;
      }
    }

    .grey-box {
      background-color: rgb(82 98 108 / 5%);
      height: 150px;
      border-radius: 5px;
      position: relative;
      margin: 0 30px;
      width: calc(100% - 60px);
      margin-bottom: 43px;
      &.audio {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
      @media (max-width: 991px) {
        margin: 0 20px;
        width: calc(100% - 40px);
      }
      p {
        max-width: 430px;
        width: 100%;
        margin: 0 auto;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 25px;
        text-align: center;
        color: #1b1726;
        padding: 0 15px;

        @media (max-width: 767px) {
          font-size: 12px;
          line-height: 22px;
        }
      }

      .waves-placeholder {
        overflow: hidden;
        height: 50px;
        position: absolute;
        bottom: 5px;
        left: auto;

        img {
          max-width: 100%;
        }
      }

      &.box-center {
        flex-direction: column;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 43px;
      }

      .loaderWrap {
        p {
          margin-top: 18px;
          margin-bottom: 0;
          font-style: italic;
          font-weight: 400;
          font-size: 14px;
          line-height: 17px;
          color: #52626c;
        }
      }
    }
  }

  //box-space

  .card-footer {
    margin-top: 43px;
    justify-content: space-between !important;
    padding: 0 30px;
    .clearBtn {
      font-weight: 700;
      font-family: "Pretendard-Bold";
      width: 91px;
      margin-right: 10px;
      @media (max-width: 767px) {
        margin-right: 0; 
        margin-bottom: 15px;
        width: 100%;
      }
    }
  
    .action-right {
      display: flex;
      align-items: center;
      > div {
        @media (max-width: 767px) {
          width: 100%;
        }
      }
      .resetBtn {
        color: #25adfc;
        background: rgb(37 173 252 / 10%);
        margin-right: 20px;
        @media (max-width: 767px) {
          margin-right: 0;
          margin-bottom: 10px;
          width: 100%;
        }
        &:disabled {
          opacity: 1;
          color: rgba(37, 173, 258, 0.5) !important;
        }
      }
    }
    @media (max-width: 991px) {
      padding: 0 20px;
    }

    @media (max-width: 767px) {
      margin-top: 25px;
      flex-direction: column;

      .action-left {
        width: 100%;
        flex-direction: column;

        .DropdownWithOverLay .dropdown-toggle {
          width: 100% !important;
          background-color: #fff !important;
          justify-content: space-between;
          padding: 0 15px;
        }
      }

      .action-right {
        width: 100%;
        flex-direction: column;
      }
    }

    .dropdown-menu {
      min-width: 224px !important;
      padding: 22px 6px 30px 20px;

      .dropdown-header {
        margin-bottom: 20px;
      }

      .dropdown-item {
        min-height: 50px;
        padding: 13px 20px 12px;
        font-weight: 600;
        font-family: "Pretendard-SemiBold";
        font-size: 13px;
        line-height: 16px;
        color: #52626c;

        .icon-txt {
          margin-left: 15px;
        }
      }

      .dropdwon-scroll {
        height: 180px;
      }
    }

    .action-left {
      display: flex;
      align-content: center;
    }

    .startRecordingBtn {
      min-width: 155px;
      height: 30px;
      background: #25adfc;
      border-radius: 5px;
      border-width: 0;
      font-style: normal;
      font-weight: 700;
      font-family: "Pretendard-Bold";
      font-size: 12px;
      line-height: 15px;
      text-align: center;
      text-transform: uppercase;
      color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 6px 15px;

      @media (max-width: 767px) {
        min-width: 100%;
      }
    }
  }

  .header-with-error {
    .card-footer {
      margin-top: 30px;
      margin-bottom: 30px;

      @media (max-width: 767px) {
        margin-top: 25px;
      }
    }
  }
}

.box-empty-state {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 62px;
  margin-bottom: 63px;

  @media (max-width: 767px) {
    margin-top: 88px;
    margin-bottom: 89px;
  }

  .empty-state-Img {
    img {
      @media (max-width: 767px) {
        max-width: 50px;
      }
    }
  }

  .empty-title {
    margin-top: 30px;
    margin-bottom: 6px;
    font-style: normal;
    font-weight: 600;
    font-family: "Pretendard-SemiBold";
    font-size: 16px;
    line-height: 19px;
    color: #1b1726;
  }

  .empty-txt {
    width: 100%;
    max-width: 350px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 25px;
    text-align: center;
    color: #52626c;
    margin: 0 auto;
    @media (max-width: 767px) {
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  .call-to-action-wrap {
    margin-top: 20px;

    .StartRegistrationBtn {
      border-width: 0;
      height: 30px;
      background: #25adfc;
      border-radius: 5px;
      font-style: normal;
      font-weight: 700;
      font-family: "Pretendard-Bold";
      font-size: 12px;
      line-height: 15px;
      text-align: center;
      text-transform: uppercase;
      color: #ffffff;
    }
  }

  &.activeRegisteringState {
    .StartRegistrationBtn {
      width: 161px;
    }
  }

  &.activeAuthenticateVoice {
    .StartRegistrationBtn {
      width: 181px;
    }

    .empty-txt {
      max-width: 450px;
      width: 100%;
    }
  }
}

.customUploadBtn {
  position: relative;
  margin-left: 10px;

  input[type="file"] {
    display: none;
  }

  @media (max-width: 767px) {
    margin: 10px 0;
  }

  .custom-file-upload {
    margin: 0;
    cursor: pointer;
    width: 185px;
    height: 30px;
    background: rgb(82 98 108 / 5%);
    border-radius: 5px;
    font-weight: 700;
    font-family: "Pretendard-Bold";
    font-size: 12px;
    line-height: 15px;
    text-transform: uppercase;
    color: #52626c;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 767px) {
      width: 100%;
    }

    .uploadTxt {
      margin-left: 11px;

      @media (max-width: 767px) {
        margin-left: 10px;
      }
    }
  }
}

.containerWrap {
  position: relative;
  margin: 0 auto;
  width: 100%;
  // width: 300px;
  // display: flex;
  // align-items: center;

  @media (max-width: 767px) {
    width: 255px;
    justify-content: center;
  }
}

.RecordVoiceContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 25px;
  flex-direction: column;

  .redoWrap {
    cursor: pointer;
    margin-left: 10px;
  }

  .recordVoiceBtn {
    min-width: 300px;
    max-width: 300px;
    margin: 0 auto;
    height: 45px;
    background: rgb(82 98 108 / 10%);
    border-radius: 100px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    position: relative;
    cursor: pointer;

    @media (max-width: 767px) {
      min-width: 200px;
    }

    &.activeState {
      // position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      background: #52626c1a;
      min-width: 250px;

      @media (max-width: 767px) {
        min-width: 150px;
      }

      .icon-txt {
        color: #ffffff;

        @media (max-width: 767px) {
          padding-left: 18px;
        }
      }
    }

    .icon {
      position: absolute;
      left: 17px;
      top: 10px;

      @media (max-width: 767px) {
        left: 15px;
      }
    }

    .icon-txt {
      font-style: normal;
      font-weight: 700;
      font-family: "Pretendard-Bold";
      font-size: 15px;
      line-height: 18px;
      color: #52626c;
      z-index: 2;
      position: relative;
      @media (max-width: 767px) {
        margin-left: 0;
      }
    }
  }
}

.uploaded-file-container {
  display: flex;
  flex-direction: column;
  padding: 0 30px 30px;
  @media (max-width: 991px) {
    padding: 0 20px 30px;
  }

  .uploaded-file-row {
    margin-top: 20px;
    height: 70px;
    background: rgb(82 98 108 / 5%);
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    padding: 20px;

    @media (max-width: 767px) {
      flex-direction: column;
      height: 95px;
      align-items: flex-start;
      justify-content: flex-start;
    }

    .progress {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 5px;
      background-color: rgb(82 98 108 / 10%);
      border-radius: 5px;

      .progress-bar {
        background: #25adfc;
        border-width: 0;
        border-radius: 0;
      }
    }

    .uploaded-file-content {
      display: flex;
      align-items: center;

      .fileName {
        margin-left: 20px;
        font-style: normal;
        font-weight: 600;
        font-family: "Pretendard-SemiBold";
        font-size: 14px;
        line-height: 17px;
        color: #1b1726;

        @media (max-width: 767px) {
          font-size: 12px;
          line-height: 15px;
        }
      }
    }

    .uploaded-file--status {
      margin-left: auto;

      @media (max-width: 767px) {
        margin-left: 0;
        width: 100%;
        margin-top: 10px;
      }

      .badge-status {
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 91px;
        height: 24.82px;
        background: rgb(4 217 178 / 10%);
        border-radius: 5px;

        @media (max-width: 767px) {
          height: 25px;
          min-width: 100%;
        }

        .status-icon {
          margin-right: 5px;
          display: flex;
          align-items: center;
        }

        .status-txt {
          font-style: normal;
          font-weight: 600;
          font-family: "Pretendard-SemiBold";
          font-size: 14px;
          line-height: 17px;
          margin-top: -1px;
        }
      }
    }

    &.statusPassed {
      background: rgb(4 217 178 / 5%);

      .badge-status {
        background: rgb(4 217 178 / 10%);
      }

      .status-txt {
        color: #04d9b2;
      }
    }

    &.statusFailed {
      background: rgb(255 76 76 / 5%);

      .badge-status {
        background: rgb(255 76 76 / 10%);
      }

      .status-txt {
        color: #ff4c4c;
      }
    }

    &.statusFileError {
      background: rgb(237 202 42 / 5%);

      .badge-status {
        background: rgb(237 201 41 / 10%);
      }

      .badge-status {
        min-width: 102px;
      }

      .status-txt {
        color: #edca2a;
      }
    }
  }
}

.hoverState {
  background: rgba(82, 98, 108, 0.05);
  border-radius: 5px;
  margin-bottom: 15px;
  min-height: 50px;
  padding: 0px 20px 0;
  &.active {
    background: #25adfc;
    color: #fff !important;
    .dropdown-item {
      color: #fff !important;
      background: #25adfc;
    }
  }
  .icon {
    cursor: pointer;
  }
  &:hover {
    background: #25adfc;
    color: #fff !important;
    .dropdown-item {
      color: #fff !important;
    }
  }
  .dropdown-item {
    border-radius: 0;
    margin: 0;
    background-color: transparent;
    min-height: auto;
    padding: 0 !important;

    &:hover {
      background-color: transparent;
    }
  }
}
// progress bar css
::before,
*::after {
  box-sizing: border-box;
}
body {
  padding: 0;
  margin: 0;
}
.progress-bar {
  position: relative;
  width: 500px;
  height: 5px;
  background-color: #111;
  border-radius: 0;
  color: white;
}

.progress-bar::before {
  content: attr(data-label);
  display: flex;
  align-items: center;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  background-color: #25adfc;
  border-radius: 0;
  padding: 1em;
  z-index: -1;
}

// added styling
.recording-audio-row {
  display: flex;
  justify-content: center;
  &.with-redoBtn {
    align-items: center;
    max-width: 300px;
    width: 100%;
    margin: 0 auto;
    position: relative;
    padding: 0 20px;    
    @media (max-width: 767px) {
      justify-content: flex-start;
      padding: 0;
      max-width: 100%;
      .recordVoiceBtn{
        width: 100% !important;
        max-width: 200px !important;
      }
    }
    .activeState{
      min-width: 300px !important;
      @media (max-width: 767px) {
        min-width: 200px !important;
      }
    }
    .redoWrap {
      position: absolute;
      right: -55px;
      margin-left: 0;
      z-index: 1;
      @media (max-width: 767px) {
        right: 0;
      }
    }
  }
  .progress-bar,
  .recordVoiceBtn {
    max-width: 300px;
    margin: 0;
  }
}
.recordVoiceProgress {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  border-radius: 100px;
  background: rgba(82, 98, 108, 0.1);
  .progress-bar {
    background: #25adfc;
    height: 100%;
  }
}

.mt-25{
  margin-top: 25px !important;
}