.VoiceChatContainer {
  .meetingItemScroll {
    height: 393px;
    overflow: auto;
    margin-top: 20px;
    @media (max-width: 767px) {
      height: 305px;
    }
  }
  h1 {
    align-items: flex-end;
    span {
      &:first-child {
        @media (max-width: 767px) {
          max-width: 215px;
        }
      }
    }
    .btnDemo {
      top: -6px;
      position: relative;
      @media (max-width: 767px) {
        top: 0;
      }
    }
  }
  .realTimeMeeting {
    .empty-box-image {
      display: flex;
      margin-left: -10px;
      margin-right: -10px;
      .col-50 {
        width: 50%;
        max-width: 50%;
        padding-left: 10px;
        padding-right: 10px;
        img {
          max-width: 100%;
        }
      }
    }
    .box-empty-state {
      margin-top: 92px;
      margin-bottom: 74px;
      @media (max-width: 767px) {
        margin-top: 20px;
        margin-bottom: 0;
      }
      &.EnterChatRoomBox {
        .empty-title {
          margin-top: 20px;
          margin-bottom: 10px;
        }
        .empty-txt {
          max-width: 273px;
        }
      }
      &.StartYourConversation {
        margin-top: 0;
        margin-bottom: 0;
        .roomId {
          display: block;
          font-style: normal;
          font-weight: 400;
          font-size: 11px;
          line-height: 13px;
          margin-top: 5px;
          color: #52626c;
        }
        .outlineBoxPeople {
          background: rgb(37 173 252 / 5%);
          border-radius: 5px;
          display: flex;
          align-items: center;
          justify-content: center;
          overflow: hidden;
          margin-left: 10px;
          &.size30 {
            width: 30px;
            height: 30px;
          }
        }
        .peopleBtnDropDown {
          margin-top: 4px;
          display: flex;
          align-items: center;
          @media (max-width: 767px) {
            margin-top: 15px;
            width: 100%;
            flex-direction: row !important;
            margin-bottom: 10px;
            .DropdownWithOverLay {
              width: calc(100% - 40px) !important;
            }
          }
        }
        .title-card {
          font-weight: 600;
          font-family: "Pretendard-SemiBold";
          font-size: 16px;
          line-height: 19px;
          color: #1b1726;
          margin-top: 0;
          @media (max-width: 767px) {
            font-weight: 700;
            font-family: "Pretendard-Bold";
            font-size: 18px;
            line-height: 22px;
          }
        }
        .DropdownWithOverLay {
          .dropdown-toggle {
            border-width: 0;
            background: rgb(255 255 255 / 10%);
            width: 113px;
            border: 1px solid rgb(82 98 108 / 10%);
            @media (max-width: 767px) {
              width: 100%;
              justify-content: space-between;
            }
          }
          .dropdown-menu {
            min-width: 216px !important;
            left: auto !important;
            padding: 10px 20px !important;
          }
          .dropdwon-scroll {
            height: 80px !important;
            margin-top: 0 !important;
            padding-right: 0 !important;
          }
          .checkbox-container {
            padding: 12px 0px 11px !important;
            line-height: 17px !important;
            .checkmark {
              right: 0 !important;
            }
          }
        }
        .SettingsBtnDropDown {
          .dropdown-header {
            margin-top: 0;
            margin-bottom: 30px;
          }
          .members-table {
            max-width: 100%;
          }
          .dropdDownFooter {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            margin-top: 15px;
          }
          .DiscardBtn {
            height: 30px;
            width: 94px;
            background: rgb(82 98 108 / 5%);
            border-radius: 5px;
            font-size: 12px;
            font-weight: 600;
            font-family: "Pretendard-SemiBold";
            border-width: 0;
            font-style: normal;
            line-height: 15px;
            text-align: center;
            text-transform: uppercase;
            color: #52626c;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 10px;
            &.disabled-btn {
              color: rgba(82, 98, 108, 0.5) !important;
            }
          }
          .ApplyBtn {
            height: 30px;
            width: 91px;
            background: #25adfc;
            border-radius: 5px;
            font-size: 12px;
            font-weight: 700;
            font-family: "Pretendard-Bold";
            border-width: 0;
            font-style: normal;
            line-height: 15px;
            text-align: center;
            text-transform: uppercase;
            color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
        .ExitChatRoomBtn {
          height: 30px;
          width: 144px;
          background: rgb(82 98 108 / 5%);
          border-radius: 5px;
          font-size: 12px;
          font-weight: 700;
          font-family: "Pretendard-Bold";
          border-width: 0;
          font-style: normal;
          line-height: 15px;
          text-align: center;
          text-transform: uppercase;
          color: #52626c;
          display: flex;
          align-items: center;
          justify-content: center;
          @media (max-width: 767px) {
            width: 100%;
          }
        }
        .card-header,
        .card-footer {
          width: 100%;
          @media (max-width: 767px) {
            flex-direction: column;
            align-items: flex-start;
            .action-left {
              width: 100%;
              flex-direction: column;
            }
            .action-right {
              width: 100%;
              flex-direction: column;
            }
            .title-action-btn {
              flex-direction: column;
              .DropdownWithOverLay {
                width: 100%;
              }
            }
          }
        }
        .card-header {
          margin-bottom: 0;
        }
        .card-footer {
          justify-content: space-between !important;
          .langurageBtnDropDown {
            display: flex;
            align-items: center;
            .DropdownWithOverLay {
              .dropdown-menu {
                min-width: 220px !important;
                left: 0 !important;
                padding: 10px 20px !important;
              }
              .dropdwon-scroll {
                height: 80px !important;
                margin-top: 0 !important;
                padding-right: 0 !important;
              }
              .checkbox-container {
                padding: 12px 0px 11px !important;
                line-height: 17px !important;
                .checkmark {
                  right: 0 !important;
                }
              }
            } // DropdownWithOverLay

            .mute-action {
              margin-left: 10px;
              @media (max-width: 767px) {
                margin-left: 0;
                margin-top: 10px;
                margin-bottom: 10px;
                width: 100%;
              }
              .UnmuteBtn {
                height: 30px;
                width: 120px;
                border-radius: 5px;
                font-size: 12px;
                font-weight: 700;
                font-family: "Pretendard-Bold";
                border-width: 0;
                font-style: normal;
                line-height: 15px;
                text-align: center;
                text-transform: uppercase;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #25adfc;
                background: rgb(37 173 252 / 5%);
                @media (max-width: 767px) {
                  width: 100%;
                }
                img {
                  width: 15px;
                  height: 15px;
                }
                &.mic-blue-btn {
                  color: #ff4c4c;
                  background: rgb(255 76 76 / 5%);
                }
                .ic-txt {
                  margin-left: 5px;
                }

                // .icon {
                //   img {
                //     width: 12px;
                //   }
                // }
              }
            }
            .DropdownWithOverLay {
              .dropdown-toggle {
                border-width: 0;
                background: rgb(255 255 255 / 10%);
                width: 169px;
                border: 1px solid rgb(82 98 108 / 10%);
                @media (max-width: 767px) {
                  width: 100%;
                  justify-content: space-between;
                }
              }
              .dropdown-menu {
                min-width: 580px;
                padding: 20px;
              }
            }
          }
        }

        .placeholderBox {
          margin-top: 141px;
          margin-bottom: 172px;
          @media (max-width: 767px) {
            margin-top: 90px;
            margin-bottom: 75px;
          }
          .outlineBox {
            text-align: center;
            margin: auto;
          }
          .empty-title {
            display: block;
            margin-bottom: 0;
            margin-top: 20px;
          }
        }
      }
      &.MemberSettingsBox {
        margin-top: 24px;
        margin-bottom: 15px;
        @media (max-width: 767px) {
          margin-top: 14px;
          margin-bottom: 15px;
        }
      }
      .empty-title {
        margin-top: 25px;
        margin-bottom: 10px;
      }
      .empty-txt {
        max-width: 400px;
      }
      .call-to-action-wrap {
        margin-top: 30px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        @media (max-width: 767px) {
          margin-top: 40px;
        }
        .NewChatRoomBtn {
          height: 40px;
          max-width: 300px;
          width: 100%;
          background: #25adfc;
          box-shadow: 2px 5px 15px rgba(0, 0, 0, 0.05);
          border-radius: 5px;
          font-size: 13px;
          font-weight: 800;
          font-family: "Pretendard-ExtraBold";
          border-width: 0;
          font-style: normal;
          line-height: 16px;
          text-align: center;
          text-transform: uppercase;
          color: #ffffff;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .ExistingChatRoomBtn {
          margin-top: 15px;
          height: 40px;
          max-width: 300px;
          width: 100%;
          background: rgb(82 98 108 / 5%);
          // box-shadow: 2px 5px 15px rgba(0, 0, 0, 0.05);
          border-radius: 5px;
          font-size: 13px;
          font-weight: 800;
          font-family: "Pretendard-ExtraBold";
          border-width: 0;
          font-style: normal;
          line-height: 16px;
          text-align: center;
          text-transform: uppercase;
          color: #52626c;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      &.EnterExistingChatRoom {
        margin-top: 96px;
        margin-bottom: 85px;
        @media (max-width: 767px) {
          margin-top: 20px;
          margin-bottom: 20px;
        }
        form {
          width: 100%;
          max-width: 400px;
          margin: 0 auto;
        }

        .empty-txt {
          margin-bottom: 30px;
        }
        .empty-title {
          margin-top: 20px;
        }
        .call-to-action-wrap {
          margin-top: 20px;
          flex-direction: column;
          line-height: normal;
        }
        .EnterChatRoomBtn {
          height: 40px;
          max-width: 400px;
          width: 100%;
          background: #25adfc;
          box-shadow: 2px 5px 15px rgba(0, 0, 0, 0.05);
          border-radius: 5px;
          font-size: 13px;
          font-weight: 800;
          font-family: "Pretendard-ExtraBold";
          border-width: 0;
          font-style: normal;
          line-height: 16px;
          text-align: center;
          text-transform: uppercase;
          color: #ffffff;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .goBackLink {
          margin-top: 20px;
          line-height: normal;
          .link {
            font-style: normal;
            font-weight: 500;
            font-family: "Pretendard-Medium";
            font-size: 13px;
            line-height: 16px;
            text-decoration-line: underline;
            color: #52626c;
            cursor: pointer;
          }
        }
      }
      &.MemberSettingsBox {
        .empty-title {
          margin-top: 20px;
          margin-bottom: 30px;
        }
      }
    }
  }
  .chatRoomItems {
    margin-bottom: 4px;
    .itemRow {
      border-width: 0;
      padding: 20px 15px 20px 15px;
      margin-top: 20px;
      &:first-child {
        margin-top: 0px;
      }
      @media (max-width: 767px) {
        padding: 20px 15px 15px 15px;
        margin-top: 10px;
      }
      .timeIcon {
        margin-right: 6px !important;
        line-height: 12px;
      }
      &.chatReply {
        background: #25adfc;
        max-width: calc(100% - 60px);
        margin-left: auto;
        @media (max-width: 767px) {
          max-width: calc(100% - 0px);
        }
        .dot-flashing {
          position: relative;
          width: 6px;
          height: 6px;
          border-radius: 5px;
          background-color: #52626c1a;
          color: #52626c1a;
          animation: dotFlashing2 1s infinite linear alternate;
          animation-delay: 0.5s;
        }
        .dot-flashing::before {
          left: -10px;
          width: 6px;
          height: 6px;
          border-radius: 5px;
          background-color: #52626c1a;
          color: #52626c1a;
          animation: dotFlashing2 1s infinite alternate;
          animation-delay: 0s;
        }
        
        .dot-flashing::after {
          left: 10px;
          width: 6px;
          height: 6px;
          border-radius: 5px;
          background-color: #52626c1a;
          color: #52626c1a;
          animation: dotFlashing2 1s infinite alternate;
          animation-delay: 1s;
        }
        
        
        .loader-wrap {
          // @keyframes dotFlashing {
          //   0% {
          //     background-color: #fff;
          //   }
          //   50%,
          //   100% {
          //     background-color: rgb(255 255 255 / 5%);
          //   }
          // }
        }
        .title {
          color: #fff;
          font-weight: 700;
          font-family: "Pretendard-Bold";
        }
        .updaterd-time-txt {
          color: #fff;
          font-weight: 500;
          font-family: "Pretendard-Medium";
        }
        .itemBodyTxt {
          font-weight: 500;
          font-family: "Pretendard-Medium";
          color: #fff;
        }
      }
      .itemBodyTxt {
        max-width: 1050px;
      }
    }
  }
} //VoiceChatContainer
.h-70 {
  height: 70px;
  width: 100%;
}

.peopleContent {
  .people-nameAction {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 40px;
    &.peopleDisabled {
      opacity: 0.5;
      .peopleName {
        color: #52626c;
      }
    }
    .peopleName {
      font-style: normal;
      font-weight: 500;
      font-family: "Pretendard-Medium";
      font-size: 14px;
      line-height: 17px;
      color: #25adfc;
    }
    .action {
      margin-left: auto;
    }
  }
}

.DropdownWithOverLay.inviteBtn .dropdown-toggle::after {
  display: none !important;
}
.DropdownWithOverLay.inviteBtn {
  margin-left: 10px;
  .btn-primary.active,
  &.show > .btn-primary.dropdown-toggle {
    color: #52626c !important;
    background: rgb(37 173 252 / 5%) !important;
    border: 0 solid rgb(82 98 108 / 10%) !important;
  }
  .dropdown-menu {
    width: 360px;
    @media (max-width: 767px) {
      width: 300px;
    }
  }
  .invitePeopleContainer {
    padding: 20px 10px;
    .title {
      display: block;
      font-style: normal;
      font-weight: 700;
      font-family: "Pretendard-Bold";
      font-size: 20px;
      line-height: 24px;
      color: #1b1726;
    }
    .CopyInviteBtn {
      height: 40px;
      width: 100%;
      background: #25adfc;
      border-radius: 5px;
      font-size: 12px;
      font-weight: 700;
      font-family: "Pretendard-Bold";
      border-width: 0;
      font-style: normal;
      line-height: 15px;
      text-align: center;
      text-transform: uppercase;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .inviteForm {
      margin-top: 20px;
      margin-bottom: 20px;
      min-height: 70px;
      line-height: normal;
      background: rgb(82 98 108 / 5%);
      padding: 16px;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      text-align: center;
      .invite-label {
        font-weight: 400;
        font-size: 13px;
        line-height: 16px;
        color: #52626c;
        display: block;
        margin-bottom: 5px;
      }
      .label-link {
        font-family: "Pretendard-SemiBold";
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: #1b1726;
      }
    }
  }

  .dropdown-toggle {
    background: rgb(37 173 252 / 5%) !important;
    border-radius: 5px !important;
    width: 30px !important;
    height: 30px !important;
    font-size: 0 !important;
    border-width: 0 !important;
    box-shadow: none !important;
    padding: 0;
    &::before {
      background: url("../../assets/images/ic-people.svg");
      width: 30px;
      height: 30px;
      background-repeat: no-repeat;
      content: "";
      display: block;
      background-size: 15px 16px;
      background-position: center center;
      position: absolute;
      top: auto;
      left: auto;
      z-index: 1;
    }
    &.after {
      display: none !important;
    }
  }
}
