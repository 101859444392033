@import "../../assets/scss/animation.scss";
.AutoSummarizationContainer {
  margin-top: 60px;
  @media (max-width: 991px) {
    padding-right: 20px;
    padding-left: 20px;
    padding-top: 90px;
    margin-top: 40px;
  }
  .checkbox-container.active {
    background-color: rgba(37, 173, 252, 0);
  }
  .autoSummeriDropdown .dropdown-menu {
    min-width: 225px !important;
  }
  .continuingTerms {
    font-style: italic;
    font-weight: 400;
    font-size: 11px;
    line-height: normal;
    color: #52626c;
    margin-bottom: 77px;
    text-align: center;
    a {
      color: #25adfc;
      text-decoration: underline;
    }
    @media (max-width: 767px) {
      margin-bottom: 30px;
      margin-top: 15px;
    }
  }
  + .power--Banner {
    .bannerBox {
      margin-top: 45px;
      @media (max-width: 991px) {
        margin-top: 20px;
      }
    }
  }
}
.AutoSummarizationRow {
  margin-left: -20px;
  margin-right: -20px;
  display: flex;
  flex-wrap: wrap;
  // * {
  //   scrollbar-width: auto;
  //   scrollbar-color: rgb(82 98 108 / 10%) #ffffff;
  // }

  // *::-webkit-scrollbar {
  //   width: 12px;
  // }

  // *::-webkit-scrollbar-track {
  //   background: #ffffff;
  // }

  // *::-webkit-scrollbar-thumb {
  //   background-color: rgb(82 98 108 / 10%);
  //   border-radius: 10px;
  //   border: 3px solid #ffffff;
  // }
  @media (max-width: 767px) {
    .col-12 {
      &:nth-child(2) {
        // order: 3;
      }
    }
  }
}
.AutoSummarizationCard {
  padding-right: 20px;
  padding-left: 20px;
  @media (max-width: 767px) {
    margin-bottom: 15px;
  }
  .card {
    background: #ffffff;
    box-shadow: 2px 5px 15px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    padding: 20px 30px 30px 30px;
    position: relative;
    border-width: 0;
    min-height: 100%;
    @media (max-width: 991px) {
      padding: 20px;
    }
  }
  .card-header {
    background-color: transparent;
    border-bottom-width: 0;
    padding: 0;
    border-radius: 0;
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;
    align-items: center;
    // min-height: 30px;
    @media (max-width: 991px) {
      margin-bottom: 12px;
    }
    .title-card {
      font-weight: 700;
      font-family: 'Pretendard-Bold';
      font-size: 18px;
      line-height: 22px;
      color: #1b1726;
      margin-bottom: 0;
      margin-top: 4px;
    }
    .title-action-btn {
      .tryExampleBtn {
        width: 144px;
        height: 30px;
        background: rgb(255 255 255 / 10%);
        border: 1px solid #52626c;
        border-radius: 5px;
        font-weight: 700;
        font-family: 'Pretendard-Bold';
        font-size: 12px;
        line-height: normal;
        text-transform: uppercase;
        color: #52626c;
        display: flex;
        align-items: center;
        justify-content: center;
        span {
          &:first-child {
            margin-right: 5px;
          }
          &:last-child {
            margin-left: 5px;
          }
        }
      }
    }
  }
  .card-body {
    padding: 0;
    min-height: 212px;
    .card-text {
      font-weight: 400;
      font-size: 15px;
      line-height: 30px;
      color: #52626c;
      margin-top: 4px;
    }
  }
  .card-footer {
    background-color: #fff;
    border-width: 0;
    border-radius: 0;
    padding: 0;
    margin-top: 16px;
    display: flex;
    align-items: center;
    justify-content: flex-end !important;
    .clearBtn {
      width: 60px;
      height: 30px;
      background: transparent;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-width: 0;
      font-weight: 600;
      font-family: 'Pretendard-SemiBold';
      font-size: 12px;
      line-height: normal;
      text-transform: uppercase;
      color: rgb(82 98 108 / 100%);
      text-decoration: none;
      &.disabled-btn {
        color: rgb(82 98 108 / 50%) !important;
      }
    }
    .submitBtn {
      width: 90px;
      height: 30px;
      background: #25adfc;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 10px;
      font-weight: 700;
      font-family: 'Pretendard-Bold';
      font-size: 12px;
      line-height: normal;
      text-transform: uppercase;
      color: #fff;
      text-decoration: none;
      &.disabled-btn {
        color: rgb(255 255 255 / 50%) !important;
      }
    }
  }
  .cardText {
    .form-control {
      padding-left: 0;
      padding-right: 0;
      resize: none;
      border-width: 0;
      height: 200px;
      font-weight: 400;
      font-size: 15px;
      color: rgb(82 98 108 / 100%);
      line-height: 30px;
      &::placeholder {
        color: rgb(82 98 108 / 50%);
      }
      &:focus {
        box-shadow: none !important;
      }
    }
  }
  .cardSummary {
    .summaryCardAEmptyBox {
      min-height: 250px;
      display: flex;
      align-items: center;
      justify-content: center;
      @media (max-width: 991px) {
        min-height: 272px;
      }
      img {
        width: auto;
        height: auto;
        max-width: 100%;
      }
    }
    .summaryCardLoading {
      min-height: 250px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      .loaderWrap {
        p {
          margin-bottom: 0;
          margin-top: 20px;
          font-style: italic;
          font-weight: 400;
          font-size: 14px;
          line-height: 17px;
          color: #52626c;
          text-align: center;
        }
      }
    }
  }
}
.DropdownWithOverLay {
  &.SpeakerBtnCustom{
    .checkbox-container:focus {
      background-color: transparent;
    }  
  }
  &.show {
    &::before {
      display: none;
      content: "";
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgb(0 0 0 / 6%);
      z-index: 102;
    }
  }
  .dropdown-menu {
    background: #ffffff;
    box-shadow: 2px 5px 15px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    border-width: 0;
    padding: 22px 6px 20px 20px;
    min-width: 490px;
    z-index: 1055;
    margin-bottom: 32px;
    margin-top: 32px;
    @media (max-width: 767px) {
      min-width: 300px;
    }
    @media (max-width: 359px) {
      min-width: 260px !important;
    }
  }
  .dropdown-header {
    display: block;
    padding: 0;
    margin: 0;
    white-space: normal;
    font-weight: 700;
    font-family: 'Pretendard-Bold';
    font-size: 15px;
    line-height: 18px;
    color: #000000;
    margin-bottom: 21px;
  }
  .dropdown-item {
    min-height: 66px;
    background: rgba(82, 98, 108, 0.05);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    margin: 0 0 15px 0;
    justify-content: center;
    padding: 15px 20px 17px 20px;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #1b1726;
    white-space: normal !important;
    width: 100%;
    &:last-child {
      margin-bottom: 0;
    }
    &:hover {
      background: #25adfc;
      color: #fff !important;
      font-weight: 400;
    }
    &.active {
      background: #25adfc;
      color: #fff !important;
      font-weight: 600;
      font-family: 'Pretendard-SemiBold';
    }
  }
  .dropdwon-scroll {
    overflow: visible;
    height: 229px;
    padding-right: 10px;
    // &::-webkit-scrollbar {
    //   width: 4px !important;
    //   height: 60px !important;
    //   border-radius: 10px;
    //   // background-color: rgb(82 98 108 / 10%);
    //   background-color: red;
    // }
  }
  .dropdown-toggle {
    width: 144px;
    height: 30px;
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid rgb(82 98 108 / 10%);
    border-radius: 5px;
    font-weight: 700;
    font-family: 'Pretendard-Bold';
    font-size: 12px;
    line-height: normal;
    text-transform: uppercase;
    color: #52626c;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    padding-left: 15px;
    padding-right: 35px;
    &:focus {
      background: rgba(255, 255, 255, 0.1) !important;
      border: 1px solid rgb(82 98 108 / 10%);
      color: #52626c !important;
      box-shadow: none !important;
      outline: 0 !important;
    }
    &::after {
      content: "";
      margin-left: auto;
      border-width: 0;
      display: inline-block;
      width: 20px;
      height: 20px;
      background: url("../../assets/images/icon-dropDownBlack.svg");
      background-size: 100%;
      background-repeat: no-repeat;
      position: absolute;
      top: 4px;
      right: 10px;
    }
    &:disabled{
      color: rgb(82 98 108 / 50%) !important;
    }
  }
  .btn-primary.active,
  &.show > .btn-primary.dropdown-toggle {
    color: #52626c !important;
    background: rgba(255, 255, 255, 0.1) !important;
    border: 1px solid rgb(82 98 108 / 10%);
  }
}

.voiceControls {
  height: 60px;
  background: rgb(37 173 252 / 5%);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: auto -30px -30px -30px;
  padding: 15px 20px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  @media (max-width: 991px) {
    margin: auto -20px -20px -20px;
    padding: 10px 15px;
  }
  .ic-pause {
    cursor: pointer;
    img {
      max-width: 30px;
      max-height: 30px;
    }
  }
  .ic-speaker {
    cursor: pointer;
    @media (max-width: 1180px) {
      // display: none;
    }
  }
  .text {
    font-style: italic;
    font-weight: 400;
    font-size: 11px;
    line-height: 13px;
    color: #52626c;
  }
}
.DropdownBackdrop {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  opacity: 0.5;
}
.dropDown-body {
  overflow: hidden;
  padding-right: 15px;
  @media (max-width: 1199px) {
    padding-right: 0;
  }
  .DropdownWithOverLay {
    &.show {
      + .DropdownBackdrop {
        display: block;
      }
    }
  }
}

.cursorBlink {
  position: relative;
  i {
    position: absolute;
    width: 1px;
    height: 80%;
    background-color: red;
    left: 5px;
    top: 10%;
    animation-name: blink;
    animation-duration: 800ms;
    animation-iteration-count: infinite;
    opacity: 1;
  }
  .form-contorl:focus + i {
    display: none;
  }
  @keyframes blink {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
}
.CardTextScroll {
  overflow: auto;
  height: 260px;
}





.loaderWrap {
  p {
    font-style: italic;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #52626c;
  }
}