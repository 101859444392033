.profileContainer {
  margin-top: 50px;
  margin-bottom: 140px;
  @media (max-width: 991px) {
    margin-bottom: 50px;
    padding-right: 20px;
    padding-left: 20px;
    padding-top: 90px;
    margin-top: 40px;
  }
  h1 {
    font-weight: 900;
    font-family: "Pretendard-Black";
    text-align: left;
    color: #1b1726;
    margin: 0px 0 50px;
    font-size: 30px;
    line-height: 36px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    @media (max-width: 991px) {
      margin: 0px 0 40px;
      font-size: 22px;
      line-height: 27px;
    }
  }
  .profileInfoDetails {
    padding: 30px;
    background: #ffffff;
    box-shadow: 2px 5px 15px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    @media (max-width: 991px) {
      padding: 20px;
    }
  }
  .profileNameInfo {
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgb(82 98 108 / 10%);
    padding-bottom: 30px;
    margin-bottom: 30px;
    @media (max-width: 991px) {
      flex-wrap: wrap;
      padding-bottom: 20px;
      margin-bottom: 10px;
    }
    .nameInitial {
      background: rgb(37 173 252 / 10%);
      width: 80px;
      height: 80px;
      min-width: 80px;
      border-radius: 5px;
      font-style: normal;
      font-weight: 900;
      font-family: "Pretendard-Black";
      font-size: 20px;
      line-height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-transform: uppercase;
      letter-spacing: 2px;
      color: #25adfc;
      text-align: center;
    }
    .userNameEmail {
      margin-left: 20px;
      .userName {
        display: block;
        font-style: normal;
        font-weight: 700;
        font-family: "Pretendard-Bold";
        font-size: 21px;
        line-height: 25px;
        color: #1b1726;
        margin-bottom: 5px;
      }
      .userEmailId {
        font-style: normal;
        font-weight: 500;
        font-family: "Pretendard-Medium";
        font-size: 14px;
        line-height: 17px;
        color: #52626c;
        display: block;
      }
    }
    .actionBtn {
      margin-left: auto;
      @media (max-width: 991px) {
        width: 100%;
        margin-top: 20px;
      }
      .EditProfileBtn {
        width: 130px;
        height: 40px;
        background: rgb(37 173 252 / 10%);
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 700;
        font-family: "Pretendard-Bold";
        font-size: 12px;
        line-height: normal;
        text-transform: uppercase;
        color: #25adfc;
        text-decoration: none;
        border-width: 0;
        &.disabled-btn {
          color: rgb(255 255 255 / 50%) !important;
        }
        @media (max-width: 991px) {
          width: 100%;
        }
      }
    }
  }
  .profileOtherInfo {
    .row {
      margin-left: -37px;
      margin-right: -38px;
      .col-12 {
        padding-left: 37px;
        padding-right: 38px;
      }
    }
    .otherInfoData {
      height: 56px;
      padding-top: 14px;
      background: rgb(255 255 255 / 5%);
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      .InfoDataLabel {
        margin-bottom: 5px;
        font-weight: 500;
        font-family: "Pretendard-Medium";
        font-size: 12px;
        line-height: 15px;
        color: #52626c;
      }
      .InfoDataInput {
        font-style: normal;
        font-weight: 600;
        font-family: "Pretendard-SemiBold";
        font-size: 14px;
        line-height: 17px;
        color: #1b1726;
      }
    }
  }
  .profileBox {
    margin-top: 30px;
    padding: 20px 30px 20px 27px;
    background: #ffffff;
    box-shadow: 2px 5px 15px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    display: flex;
    align-items: center;
    @media (max-width: 991px) {
      margin-top: 20px;
      padding: 20px;
    }
    .boxHeading {
      font-style: normal;
      font-weight: 700;
      font-family: "Pretendard-Bold";
      font-size: 15px;
      line-height: 18px;
      color: #1b1726;
      margin-left: 20px;
    }
    .actionIcon {
      margin-left: auto;
    }
    &.changePassword {
      cursor: pointer;
    }
    &.deleteAccount {
      cursor: pointer;
    }
  }
}

.changePasswordModal {
  .modal-title {
    margin-bottom: 0;
  }
}

.DeleteAccountModal {
  .modal-dialog {
    @media (min-width: 576px) {
      max-width: 410px;
    }
  }
  .btn-close {
    display: none;
  }
  .action-btn {
    justify-content: space-between;
    display: flex;
    align-items: center;
    .btnNo {
      width: 165px;
      height: 40px;
      background: rgb(82 98 108 / 5%);
      border-radius: 5px;
      border-width: 0;
      font-weight: 700;
      font-family: "Pretendard-Bold";
      font-size: 12px;
      line-height: normal;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      text-transform: uppercase;
      color: #52626c;
      margin: 0;
    }
    .btnYes {
      width: 165px;
      height: 40px;
      background: #ff4c4c;
      border-radius: 5px;
      border-width: 0;
      font-weight: 700;
      font-family: "Pretendard-Bold";
      font-size: 12px;
      line-height: normal;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      text-transform: uppercase;
      color: #f8fcff;
      margin: 0;
    }
    .btn {
      width: calc(50% - 10px);
    }
  }
}

.successModal {
  .modal-content {
    background-color: #fafafa;
  }
  .modal-dialog {
    @media (min-width: 576px) {
      max-width: 360px;
    }
  }
  .modal-header {
    flex-direction: column;
  }
  .outerBox {
    margin-bottom: 22px;
    background: #fff;
    box-shadow: 2px 5px 15px rgb(0 0 0 / 5%);
    width: 70px;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
  }
  .modal-footer {
    justify-content: center !important;
  }
  p {
    text-align: center;
    margin-bottom: 0 !important;
  }
  .btn {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    background: #25adfc;
    border-radius: 5px;
    font-style: normal;
    font-weight: 700;
    font-family: "Pretendard-Bold";
    font-size: 12px;
    line-height: 16px;
    text-transform: uppercase;
    color: #ffffff;
    border-width: 0;
    width: 100%;
    margin: 0;
  }
}
