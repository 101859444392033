.Text--QandA--Container {
  .checkbox-container {
    background-color: rgba(82, 98, 108, 0.05);
  }
  .cardText {
    .title-card {
      line-height: normal;
    }
    .DropdownWithOverLay .dropdown-toggle {
      width: 123px;
      height: 25px;
      font-size: 10px;
      &::after {
        width: 15px;
        height: 15px;
        top: 6px;
        @media (min-width: 768px) {
          width: 15px;
          height: 15px;
          top: 3px;
        }
      }
      @media (max-width: 767px) {
        height: 30px;
        font-size: 12px;
        width: 144px;
      }
    }
    .card-body {
      min-height: 184px;
      max-height: 184px;
      height: 182px;
      flex: 0 1 auto;
      &.QuestionInput {
        min-height: 100%;
        max-height: 100%;
        height: 100%;
        .form-control {
          background: rgb(82 98 108 / 5%);
          border-radius: 5px;
          padding: 15px 20px;
          margin-top: 6px;
          margin-bottom: 14px;
          height: 65px;
          @media (max-width: 767px) {
            height: 70px;
          }
        }
      }
    }
    textarea.form-control {
      background: rgb(82 98 108 / 5%);
      border-radius: 5px;
      padding: 20px;
      margin-top: 6px;
      height: 180px;
      max-height: 180px;
      @media (max-width: 767px) {
        height: 184px;
        max-height: 184px;
      }
    }
  }
  .card-header {
    @media (max-width: 767px) {
      margin-bottom: 14px;
    }
  }
  .summaryCardAEmptyBox {
    margin-top: 73px;
    margin-bottom: 74px;
    @media (max-width: 767px) {
      margin-top: 8px;
      margin-bottom: 8px;
    }
  }
  .summaryCardLoading {
    margin-top: 55px;
    margin-bottom: 74px;
    @media (max-width: 767px) {
      margin-top: 8px;
      margin-bottom: 8px;
    }
  }
}

.answer-block-with-question {
  flex-direction: column !important;
  align-items: flex-start !important;
  margin-bottom: 0 !important;
  .title-card {
    margin-top: 0 !important;
    line-height: inherit !important;
  }
  .card-text {
    font-weight: 400;
    font-size: 15px;
    line-height: 30px;
    color: #52626c;
    margin-top: 10px;
    margin-bottom: 30px;
  }
}

.loaderImg {
  margin: 0 auto -15px;
  text-align: center;
}
