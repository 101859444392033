.SoundEventDetectionContainer {
  .heading-txt {
    max-width: 1000px;
  }
  h1 {
    align-items: flex-end;
    span {
      &:first-child {
        @media (max-width: 767px) {
          max-width: 165px;
        }
      }
    }
    .btnDemo {
      top: -6px;
      position: relative;
    }
  }
  .SoundEventDetection {
    padding: 0;
    .loaderWrap {
      p {
        margin-top: 20px;
      }
    }
    .summaryCardLoading {
      height: 550px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &.RecordSound {
      .card-footer {
        justify-content: flex-end !important;
        .clearBtn {
          font-weight: 700;
          font-family: "Pretendard-Bold";
          width: 91px;
        }
        .submitBtn {
          width: auto;
          padding-left: 22px;
          padding-right: 22px;
        }
      }
      .SoundEventDetectionContentBox {
        padding: 30px 30px 48px 30px;
        @media (max-width: 991px) {
          padding: 20px 20px 28px 20px;
        }
      }
      .RecordSound-title {
        font-style: normal;
        font-weight: 600;
        font-family: "Pretendard-SemiBold";
        font-size: 16px;
        line-height: 19px;
        color: #1b1726;
        display: block;
      }
      .RecordSound-txt {
        display: block;
        margin: 6px 0 30px;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: #52626c;
      }
      .waveBox {
        height: 320px;
        padding: 20px;
        position: relative;
        z-index: 1;
        @media (max-width: 991px) {
          padding: 15px;
        }
      }
      .recordTime {
        min-width: 154px;
        font-style: normal;
        font-weight: 700;
        font-family: "Pretendard-Bold";
        font-size: 11px;
        line-height: 13px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #ffffff;
        position: absolute;
        top: 20px;
        right: 20px;
        z-index: 2;
        background: #1b1726;
        border-radius: 100px;
        height: 25px;
        padding-right: 15px;
        padding-left: 15px;
      }
    }
    .card-footer {
      justify-content: space-between !important;
      margin-top: 0;
      background: rgb(37 173 252 / 5%);
      padding: 6px 30px;
      position: relative;
      height: 80px;
      @media (max-width: 991px) {
        min-height: 60px;
        height: auto;
        padding: 20px;
      }
      .sound-row {
        padding: 0 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        @media (max-width: 991px) {
          flex-wrap: wrap;
          padding: 0;
        }
        .timeline {
          position: absolute;
          top: 1px;
          width: calc(100% - 80px);
          min-width: calc(100% - 80px);
          left: 40px;
          right: 40px;
          @media (max-width: 991px) {
            width: calc(100% - 0px);
            min-width: calc(100% - 0px);
            left: 0px;
            right: 0px;
            position: relative;
          }
        }
        .sound-left-col {
          display: flex;
          @media (max-width: 991px) {
            width: 100%;
            margin-bottom: 32px;
          }
          .sound-details {
            display: flex;
            align-items: center;
            .sound-detail-placeholder {
              width: 50px;
              height: 50px;
              background: #ffffff;
              box-shadow: 2px 5px 15px rgba(0, 0, 0, 0.05);
              border-radius: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
          .detail-txt {
            margin-left: 15px;
            .sound-name {
              font-style: normal;
              font-weight: 600;
              font-family: "Pretendard-SemiBold";
              font-size: 12px;
              line-height: 15px;
              color: #1b1726;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              max-width: 200px;
              display: block;
            }
            .sound-format {
              margin-top: 1px;
              font-style: normal;
              font-weight: 400;
              font-size: 11px;
              line-height: 13px;
              color: #52626c;
            }
          }
          .detail-txt {
            display: flex;
            flex-direction: column;
          }
        }
        .sound-center-col {
          display: flex;
          align-items: center;
          @media (max-width: 991px) {
            width: 100%;
            margin-bottom: 25px;
            margin-top: 21px;
            justify-content: center;
          }
          .music-forward,
          .music-pause,
          .music-backward {
            cursor: pointer;
          }
          .music-pause {
            margin-left: 30px;
            margin-right: 30px;
            @media (max-width: 991px) {
              margin-left: 10px;
              margin-right: 10px;
            }
          }
        }
        .sound-right-col {
          @media (max-width: 991px) {
            width: 100%;
          }
          .RecordSoundWrap {
            .StartOverBtn {
              background: rgb(82 98 108 / 5%);
              border-radius: 5px;
              height: 30px;
              width: 101px;
              display: flex;
              align-items: center;
              justify-content: center;
              font-style: normal;
              font-weight: 700;
              font-family: "Pretendard-Bold";
              font-size: 12px;
              line-height: 15px;
              text-align: center;
              text-transform: uppercase;
              color: #52626c;
              border-width: 0;
              @media (max-width: 991px) {
                width: 100%;
              }
            }
          }
        }
      }
    }
    .toolTipTimer {
      top: -24px;
      bottom: 0;
      z-index: 1;
    }
  }

  .box-empty-state {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 148px;
    margin-bottom: 147px;
    padding: 0 20px;
    @media (max-width: 991px) {
      padding: 0 15px;
    }
    @media (max-width: 767px) {
      margin-top: 88px;
      margin-bottom: 89px;
    }
    &.SoundEventDetectionEmptyBox {
      z-index: 1;
      &::before {
        background: url("../../assets/images/sed-bg.svg");
        content: "";
        width: 100%;
        height: 75%;
        position: absolute;
        top: 0;
        z-index: -1;
        left: 0;
      }
    }
    .dropdown-menu {
      min-width: 290px !important;
      padding: 22px 6px 30px 20px;
      .dropdown-header {
        margin-bottom: 20px;
      }
      .dropdown-item {
        min-height: 50px;
        padding: 13px 20px 12px;
        font-weight: 600;
        font-family: "Pretendard-SemiBold";
        font-size: 13px;
        line-height: 16px;
        color: #52626c;
        .icon-txt {
          margin-left: 0;
        }
      }
      .dropdwon-scroll {
        height: 180px;
      }
    }
    .empty-state-Img {
      img {
        @media (max-width: 767px) {
          max-width: 75%;
        }
      }
    }
    .call-to-action-wrap {
      margin-top: 30px;
      display: flex;
      align-items: center;
      @media (max-width: 767px) {
        flex-direction: column;
        width: 100%;
        span {
          width: 100%;
        }
        .dropdown-toggle {
          width: 100%;
          justify-content: space-between;
        }
        .customUploadBtn {
          margin-left: 0 !important;
          margin-right: 0 !important;
        }
        .custom-file-upload {
          width: 100% !important;
          .uploadTxt {
            width: auto;
          }
        }
        .RecordSoundWrap {
          text-align: center;
        }
        .RecordSoundBtn {
          text-align: center;
          width: 100%;
        }
      }
      .RecordSoundBtn {
        border-width: 0;
        height: 30px;
        min-width: 151px;
        background: rgb(82 98 108 / 5%);
        border-radius: 5px;
        font-style: normal;
        font-weight: 700;
        font-family: "Pretendard-Bold";
        font-size: 12px;
        line-height: 15px;
        text-align: center;
        text-transform: uppercase;
        color: #52626c;
      }
      .customUploadBtn {
        margin-left: 15px;
        margin-right: 15px;
        .custom-file-upload {
          background: #25adfc;
          border-radius: 5px;
          color: #fff;
          width: 172px;
        }
        .uploadTxt {
          margin-left: 5px;
        }
      }
    }
    .empty-title {
      margin-top: 20px;
      margin-bottom: 6px;
      font-style: normal;
      font-weight: 600;
      font-family: "Pretendard-SemiBold";
      font-size: 16px;
      line-height: 19px;
      color: #1b1726;
      text-align: center;
    }
    .empty-txt {
      width: 100%;
      max-width: 350px;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 25px;
      text-align: center;
      color: #52626c;
      margin: 0 auto;
    }
  }
  .SoundEventDetectionContentBox {
    padding: 40px 40px 48px 40px;
    @media (max-width: 991px) {
      padding: 20px 20px 28px 20px;
    }
    .waveBox {
      min-height: 202px;
      background-color: rgb(238 238 238 / 30%);
    }
    .visualizer-container {
      height: 202px;
    }
  }
  .uploaded-file-row--error{
    margin: 0 30px 30px;
    @media (max-width: 991px) {
      margin: 0 20px 20px;
    }
  }

}
.wave-action-group {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 25px;
  margin-bottom: 44px;
  @media (max-width: 767px) {
    margin-bottom: 24px;
    flex-direction: column;
    align-items: flex-start;
  }
  .wave-controls {
    display: flex;
    align-items: center;
    @media (max-width: 767px) {
      width: 100%;
      margin-bottom: 14px;
      .wave-range-control {
        width: calc(100% - 16px);
      }
    }
    .timeline {
      height: 5px;
      width: 100px;
    }
    .zoomIcon {
      margin-left: 10px;
      cursor: pointer;
    }
  }
}
.select-any-option {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  pointer-events: none;
  @media (max-width: 767px) {
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
    margin-bottom: 16px;
    order: 1;
  }
  .checkbox-container {
    width: auto;
    font-style: italic;
    font-weight: 500;
    font-family: "Pretendard-Medium";
    font-size: 12px;
    line-height: 15px;
    color: #52626c;
    padding: 0;
    margin: 0 20px 0 0;
    align-items: center;
    justify-content: flex-start;
    @media (max-width: 767px) {
      margin-right: 0;
      margin-top: 12px;
    }
    .checkmark {
      background-color: inherit;
      visibility: visible;
      border-radius: 0;
      position: relative;
      right: 0;
      top: 0;
      &:after {
        left: 5px;
        top: 2px;
        width: 4px;
        height: 8px;
        border: solid white;
        border-width: 0 2px 2px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
        background-image: none;
      }
    }
    .checkbox-txt {
      padding-left: 10px;
      color: #52626c !important;
    }
    &:hover {
      .checkmark {
        background-color: inherit;
      }
    }
    &.BothVoice {
      .checkmark {
        background: #c5c2ff;
      }
      input:checked ~ .checkmark {
        background: #c5c2ff;
      }
    }
    &.Noise {
      .checkmark {
        background: #abdffe;
      }
      input:checked ~ .checkmark {
        background: #abdffe;
      }
    }
    &.HumanVoice {
      .checkmark {
        background: #bff5eb;
      }
      input:checked ~ .checkmark {
        background: #bff5eb;
      }
    }
  }
}

.wave-data-label {
  display: flex;
  flex-wrap: wrap;
  margin-left: -10px;
  margin-right: -10px;
  margin-bottom: 27px;
  @media (max-width: 767px) {
    margin-bottom: 0;
  }
  .btn-w-50 {
    padding-left: 10px;
    padding-right: 10px;
    max-width: 50%;
    width: 50%;
    @media (max-width: 767px) {
      max-width: 100%;
      width: 100%;
      &:last-child {
        margin-top: 10px;
      }
    }
  }
  .CalucatingHumanVoiceBtn {
    height: 60px;
    background: rgba(226, 248, 235, 0.2);
    border: 2px dashed #e2f8eb;
    border-radius: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 6px 30px;
    position: relative;
    z-index: 1;
    .progressBarShadow {
      font-size: 0;
      display: block;
      box-shadow: 5px 10px 15px rgba(226, 248, 235, 0.2);
      top: 0;
      position: absolute;
      left: 0;
      height: 100%;
      border-radius: 100px;
      z-index: -1;
    }
    .btn-txt-small {
      font-style: normal;
      font-weight: 600;
      font-family: "Pretendard-SemiBold";
      font-size: 12px;
      line-height: 15px;
      color: #14876c;
      display: block;
      z-index: 1;
    }
    .btn-txt-big {
      display: block;
      font-style: normal;
      font-weight: 900;
      font-family: "Pretendard-Black";
      font-size: 20px;
      line-height: 24px;
      color: #147358;
      z-index: 1;
    }
    &.active {
      // border: 2px dashed rgba(4, 217, 178, 0);
      &::before {
        box-shadow: 5px 10px 15px rgba(4, 217, 178, 0.2);
        content: "";
        background: #04d9b2;
        z-index: -1;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        // max-width: 350px;
        border-radius: 100px;
      }
      .btn-txt-small {
        color: #fff;
      }
      .btn-txt-big {
        color: #fff;
      }
    }
    .progress {
      display: flex;
      height: 100%;
      overflow: hidden;
      font-size: 0.75rem;
      background: rgba(226, 248, 235, 0.2);
      border-radius: 100px;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 0;
      width: 100%;
      .progress-bar {
        border-width: 0;
        border-radius: 100px;
        height: 100%;
        background: #e2f8eb;
        &::before {
          background: #e2f8eb;
        }
      }
    }
  }
  .CalucatingNoiseBtn {
    height: 60px;
    background: rgba(218, 239, 243, 0.2);
    border: 2px dashed rgba(37, 173, 252, 0.2);
    border-radius: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 6px 30px;
    position: relative;
    z-index: 1;
    @media (max-width: 767px) {
      margin: 10px 0 0;
      margin-bottom: 12px;
    }
    .progressBarShadow {
      font-size: 0;
      display: block;
      box-shadow: 5px 10px 15px rgba(218, 239, 243, 0.2);
      top: 0;
      position: absolute;
      left: 0;
      height: 100%;
      border-radius: 100px;
      z-index: -1;
    }
    .btn-txt-small {
      font-style: normal;
      font-weight: 600;
      font-family: "Pretendard-SemiBold";
      font-size: 12px;
      line-height: 15px;
      color: #1e618c;
      display: block;
      z-index: 1;
    }
    .btn-txt-big {
      display: block;
      font-style: normal;
      font-weight: 900;
      font-family: "Pretendard-Black";
      font-size: 20px;
      line-height: 24px;
      color: #0e5782;
      z-index: 1;
    }
    .progress {
      display: flex;
      height: 100%;
      overflow: hidden;
      font-size: 0.75rem;
      background: rgba(218, 239, 243, 0.2);
      border-radius: 100px;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 0;
      width: 100%;
      .progress-bar {
        border-radius: 100px;
        border-width: 0;
        height: 100%;
        background: #daeff3;
        &::before {
          background: #daeff3;
        }
      }
    }
    &.active {
      // border: 2px dashed rgba(4, 217, 178, 0);
      &::before {
        content: "";
        background: #25adfc;
        box-shadow: 5px 10px 15px rgba(37, 173, 252, 0.2);
        z-index: -1;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        // max-width: 170px;
        border-radius: 100px;
      }
      .btn-txt-small {
        color: #fff;
      }
      .btn-txt-big {
        color: #fff;
      }
    }
  }
  .NoiseNotFoundBtn {
    height: 60px;
    background: rgba(255, 76, 76, 0.05);
    border-radius: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 6px 30px;
    position: relative;
    z-index: 1;
    .btn-txt-small {
      font-style: normal;
      font-weight: 600;
      font-family: "Pretendard-SemiBold";
      font-size: 12px;
      line-height: 15px;
      color: #ff4c4c;
      display: block;
    }
    .btn-txt-big {
      display: block;
      font-style: normal;
      font-weight: 900;
      font-family: "Pretendard-Black";
      font-size: 16px;
      line-height: 19px;
      color: #ff4c4c;
    }
  }
}

.SoundEventDetectionContentBox {
  .RecordSound-txt {
    + .waveBox {
      .waves-placeholder {
        height: 320px;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          max-width: 100%;
        }
      }
    }
  }  
  .waveBox {
    #microphone {
      margin-top: 32px;
      wave {
        height: 240px !important;
      }
    }
  }
}
.SoundEventDetectionContentBox div#waveform > wave > wave {
  border-right-width: 0 !important;
  &::after {
    background: url("../../assets/images/wevebar-stick.svg");
    width: 12px;
    height: 200px;
    background-repeat: no-repeat;
    content: "";
    background-position: center center;
    position: absolute;
    top: 0;
    right: -3px;
    z-index: 2;
    display: block;
    background-size: 34px 200px;
  }
}
.SoundEventDetectionContentBox div#waveform > wave {
  background-color: #fafaeb !important;
}

[data-id="both-nosie-and-voice"] {
  background: linear-gradient(180deg, #6c63ff 0%, #fff 50%) !important;
  opacity: 0.4 !important;
}

[data-id="nosie-only"] {
  background: linear-gradient(180deg, #25adfc 0%, #fff 50%) !important;
  opacity: 0.4 !important;
}
[data-id="voice-only"] {
  background: linear-gradient(
    180deg,
    rgba(52, 224, 193, 0.806826) 0%,
    #fff 50%
  ) !important;
  opacity: 0.4 !important;
}
