@import "../../../assets/scss/animation.scss";

.navbarContainer {
  height: 90px;
  border-bottom: 1px solid rgb(82 98 108 / 10%);
  position: sticky;
  top: 0;
  z-index: 101;
  background: #f8fcff !important;
  @media (max-width: 991px) {
    border-bottom: 1px solid rgb(82 98 108 / 10%);
    position: fixed;
    z-index: 20;
    top: 0;
    height: 90px;
    left: 0;
    width: 100%;
  }
  .container {
    @media (max-width: 991px) {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
  .navbar-brand {
    cursor: pointer;
    img {
      @media (max-width: 991px) {
        width: 125px;
        height: 34px;
      }
    }
  }
  .navbar-toggler {
    background: rgb(82 98 108 / 5%);
    border-radius: 5px;
    border-width: 0;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0 0 0;
    @media (min-width: 992px) {
      display: none;
    }
    .navbar-toggler-icon {
      background: url("../../../assets/images/ic-menu-close.svg") !important;
      background-size: 11px 11px !important;
      width: 11px !important;
      height: 11px !important;
      background-repeat: no-repeat;
    }
    &.collapsed {
      background: rgb(82 98 108 / 5%);
      border-radius: 5px;
      border-width: 0;
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      @media (min-width: 992px) {
        display: none;
      }
      &:focus {
        outline: 0 none;
      }
      .navbar-toggler-icon {
        background: url("../../../assets/images/ic-menu.svg") !important;
        background-repeat: no-repeat;
        background-size: 15px 13.33px !important;
        width: 15px !important;
        height: 13px !important;
      }
    }
  }
}
.accountInfoNav {
  margin-left: auto;
  @media (max-width: 991px) {
    padding: 20px;
    background-color: #fff;
  }
  .list-unstyled {
    display: flex;
    margin: 0;
    .list-item {
      margin-right: 10px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  .accountDropdown {
    &.loginAccount {
      @media (min-width: 992px) {
        .nav-link {
          font-weight: 600;
          font-family: 'Pretendard-SemiBold';
          font-size: 14px;
          line-height: 17px;
          color: #52626c;
          width: auto;
          justify-content: flex-start;
          padding-left: 13px;
          padding-right: 41px;
          position: relative;
          &::before {
            margin-right: 8px;
          }
          &::after {
            content: "";
            margin-left: auto;
            border-width: 0;
            display: inline-block;
            width: 16px;
            height: 16px;
            background: url("../../../assets/images/icon-profile-arrow.svg");
            background-size: 100%;
            background-repeat: no-repeat;
            position: absolute;
            top: 12px;
            right: 10px;
            @media (max-width: 991px) {
              display: none;
            }
          }
        }
      }
    }
    .nav-link {
      @media (min-width: 992px) {
        width: 40px;
        height: 40px;
        background: rgb(82 98 108 / 5%);
        border-radius: 5px;
        font-size: 0;
        text-decoration: none;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      &::after {
        display: none;
      }
      @media (max-width: 991px) {
        font-weight: 700;
        font-family: 'Pretendard-Bold';
        font-size: 11px;
        line-height: 13px;
        text-transform: uppercase;
        color: #1b1726;
        padding: 0;
      }
      &::before {
        content: "";
        display: inline-block;
        width: 10.67px;
        height: 14px;
        background: url("../../../assets/images/ic-user.svg");
        background-size: 10.67px 14px;
        background-repeat: no-repeat;
        @media (max-width: 991px) {
          display: none;
        }
      }
    }
  }
  .languageDropdown {
    .dropdown-item {
      padding-right: 0 !important;
      padding-left: 0 !important;
      &:hover {
        background-color: transparent !important;
      }
    }
    .nav-link {
      @media (min-width: 992px) {
        width: 40px;
        height: 40px;
        background: rgb(82 98 108 / 5%);
        border-radius: 5px;
        font-size: 0;
        text-decoration: none;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      &::after {
        display: none;
      }
      @media (max-width: 991px) {
        font-weight: 700;
        font-family: 'Pretendard-Bold';
        font-size: 11px;
        line-height: 13px;
        text-transform: uppercase;
        color: #1b1726;
        padding: 0;
      }
      &::before {
        content: "";
        display: inline-block;
        width: 17px;
        height: 17px;
        background: url("../../../assets/images/ic-language.svg");
        background-size: 100%;
        background-repeat: no-repeat;
        @media (max-width: 991px) {
          display: none;
        }
      }
    }
  }
  .dropdown-menu {
    background: #ffffff;
    box-shadow: 2px 5px 15px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    border-width: 0;
    min-width: 130px;
    right: 0;
    left: auto;
    padding: 10px 0px;
    @media (max-width: 991px) {
      padding: 10px 0 20px;
      border-bottom: 1px solid rgb(82 98 108 / 10%);
      border-radius: 0;
      margin-left: -20px;
      margin-right: -20px;
      box-shadow: none;
      display: block !important;
    }
    .dropdown-item {
      font-weight: 500;
      font-family: 'Pretendard-Medium';
      font-size: 14px;
      line-height: 17px;
      color: #52626c;
      display: flex;
      min-height: 40px;
      align-items: flex-start;
      flex-direction: column;
      justify-content: center;
      width: 100%;
      padding: 0 20px;
      &:hover {
        background-color: rgb(37 173 252 / 10%);
        color: #25adfc;
      }
      @media (max-width: 991px) {
        padding-left: 20px;
        padding-right: 20px;
      }
    }
  }
}
.BizContactBtn {
  width: 120px;
  height: 40px;
  background: #25adfc;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 800;
  font-family: 'Pretendard-ExtraBold';
  font-size: 13px;
  line-height: normal;
  text-transform: uppercase;
  color: #ffffff;
  border-width: 0;
  &:hover,
  &:focus,
  &.active,
  &:active {
    background: #25adfc !important;
  }
  

  @media (max-width: 991px) {
    width: 100%;
  }
}

// Custom CheckBox
/* Customize the label (the container) */
.checkbox-container {
  position: relative;
  margin-top: 0;
  margin-bottom: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-style: normal;
  font-weight: 500;
  font-family: 'Pretendard-Medium';
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #52626c;
  width: 100%;
  padding: 12px 20px;
  &:hover {
    // background-color: rgba(37, 173, 252, 0.1);
    background-color: transparent;
    color: #25adfc;
  }
  &:focus {
    background-color: #e9ecef;
  }
  &.active {
    background-color: rgba(37, 173, 252, 0.1);
  }
}

/* Hide the browser's default checkbox */
.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 13px;
  right: 20px;
  height: 14px;
  width: 14px;
  background-color: #eee;
  border-radius: 50%;
  visibility: hidden;
}

/* On mouse-over, add a grey background color */
.checkbox-container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.checkbox-container input:checked ~ .checkmark {
  // background-color: #25adfc;
  background-color: transparent;
  visibility: visible;
  + .checkbox-txt {
    color: #25adfc;
  }
}
.checkbox-container input:checked {
  + .checkbox-txt {
    color: #25adfc;
  }
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkbox-container .checkmark:after {
  left: 0;
  top: 0;
  border: solid white;
  border-width: 0;
  transform: rotate(0deg);
  font-weight: normal;
  width: 14px;
  height: 14px;
  background-position: center center;
  background-repeat: no-repeat;
  background: url("../../../assets/images/ic-radio-check.svg") !important;
}

// Custom Checkbox End

.navbar-collapse {
  &.collapsing {
    height: 0;
    overflow: unset;
    transition: none;
    position: fixed;
    width: 100%;
    display: none;
  }
  &.collapse {
    @media (max-width: 991px) {
      min-height: 367px !important;
      background: transparent;
      position: fixed;
      top: 90px;
      left: 0;
      width: 100%;
      padding: 0px;
      z-index: 10;
      &.show {
        &::before {
          content: "";
          display: block;
          min-height: 100% !important;
          background: rgb(0 0 0 / 70%);
          position: fixed;
          top: 90px;
          left: 0;
          width: 100%;
          z-index: -1;
        }
      }
      .accountInfoNav {
        .list-unstyled {
          flex-direction: column;
          margin: 0;
          .list-item {
            &:nth-child(1) {
              order: 3;
              margin-top: 20px;
              margin-right: 0;
            }
            &:nth-child(2) {
              order: 1;
              margin-top: 0px;
              margin-right: 0;
            }
            &:nth-child(3) {
              order: 2;
              margin-top: 20px;
            }
          }
        }
      }
    }
  }
}

.dropdown-menu {
  animation-duration: 0.3s;
  -webkit-animation-duration: 0.3s;
  animation-fill-mode: both;
  -webkit-animation-fill-mode: both;
  -webkit-animation-name: slideIn;
  animation-name: slideIn;
}
