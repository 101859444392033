.RealTimeSpeechToTextContainer {

  .whiteBgFooter:empty{
    display: none;
  }

  div#waveform > wave {
    height: 202px !important;
  }
  div#waveform > wave > wave {
    border-right-width: 0 !important;
    &::after {
      background: url("../../assets/images/wevebar-stick-2.svg");
      width: 12px;
      height: 110px;
      background-repeat: no-repeat;
      content: "";
      background-position: center center;
      position: absolute;
      bottom: 44px;
      right: -4px;
      z-index: 2;
      display: block;
      background-size: 34px 110px;
    }
  }

  .audio-player {
    bottom: -3px;
    @media (max-width: 991px) {
      margin-left: 0;
      margin-right: 0;
    }
  }
  .visualizer-container {
    height: calc(14rem - 9px);
  }
  h1 {
    align-items: flex-end;
    span {
      &:first-child {
        @media (max-width: 767px) {
          max-width: 195px;
        }
      }
    }
    .btnDemo {
      top: -6px;
      position: relative;
      @media (max-width: 767px) {
        top: 0;
      }
    }
  }
  .cardSummary {
    overflow: hidden;
    .card-body {
      min-height: 223px;
    }
  }
  .heading-txt {
    max-width: 750px;
  }
  .CardTextScroll {
    // display: flex;
    // flex-direction: column;
    // justify-content: flex-end;
    height: 223px;
    .card-text {
      display: table;
      font-size: 14px;
      font-weight: 400;
      line-height: 30px;
      overflow: hidden;
      // direction: rtl;
      width: 100%;
      margin-bottom: 0;
      > span {
        display: table-cell;
        // vertical-align: bottom;
        width: 100%;
        height: 205px;
        overflow: hidden;
        padding-bottom: 36px;
      }
    }
    .smallDotedLoading {
      position: relative;
      top: 10px;
      left: 10px;
      z-index: 1;
      @media (max-width: 991px) {
        left: 10px;
      }
    }
  }
  .VoiceWavePlacholderBox {
    height: 212px;
    display: flex;
    align-items: center;
    justify-content: center;
    span {
      img {
        max-width: 100%;
        width: 100%;
      }
    }
    .grey-box1 {
      width: 100%;
    }
  }
  .VoiceWaveFieldBox {
    height: 212px;
    display: flex;
    align-items: center;
    justify-content: center;
    .loaderwithPlaceholder {
      max-width: 100%;
    }
    .loaderImg {
      margin: 0;
    }
    .spinner {
      width: 18px;
      margin: 0;
    }
    .loaderWrap {
      display: flex;
      align-items: center;
      min-width: 212px;
      height: 34px;
      background: #f6f7f8;
      border: 4px solid #ffffff;
      border-radius: 100px;
      justify-content: center;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      max-width: 212px;
      z-index: 1;
      p {
        margin-bottom: 0;
        font-style: italic;
        font-weight: 500;
        font-family: "Pretendard-Medium";
        font-size: 12px;
        line-height: 15px;
        color: #52626c;
        margin-left: 5px;
      }
    }
  }
  .blue-footer-bg {
    background: rgb(37 173 252 / 5%);
    height: 70px;
    margin: -30px;
    @media (max-width: 991px) {
      height: auto;
      margin: -20px;
      .sound-left-col {
        width: auto !important;
        margin-bottom: 0 !important;
      }
    }
    .music-pause {
      cursor: pointer;
    }
    .toolTipTimer {
      bottom: auto;
      top: -6px;
    }
    .sound-row {
      padding-right: 20px;
      padding-left: 20px;
      .detail-txt {
        margin-left: 10px;
      }
      .timeline {
        left: 20px;
        min-width: calc(100% - 40px);
        max-width: calc(100% - 40px);
      }
    }
  }
  .AutoSummarizationCard {
    .title-card {
      margin-top: 6px;
      font-weight: 600;
      font-family: "Pretendard-SemiBold";
      font-size: 16px;
      line-height: normal;
    }
    .card-footer {
      justify-content: space-between !important;
      .action-left {
        margin-right: auto;
        display: flex;
        align-items: center;
      }
      .action-right {
        display: flex;
        align-items: center;
      }
      .custom-file-upload {
        width: 162px;
        .uploadTxt {
          margin-left: 5px;
        }
      }
      .actionBtn {
        margin-left: 14px;
      }
      .startSpeakingBtn {
        width: 135px;
        height: 30px;
        background: #25adfc;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-width: 0;
        font-weight: 700;
        font-family: "Pretendard-Bold";
        font-size: 12px;
        line-height: normal;
        text-transform: uppercase;
        color: #fff;
        text-decoration: none;
      }
      .stopRecordinggBtn {
        width: 135px;
        height: 30px;
        background: rgb(255 76 76 / 10%);
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-width: 0;
        font-weight: 700;
        font-family: "Pretendard-Bold";
        font-size: 12px;
        line-height: normal;
        text-transform: uppercase;
        color: #ff4c4c;
        text-decoration: none;
      }
      .submitBtn {
        width: 90px;
        height: 30px;
        background: #25adfc;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 10px;
        font-weight: 700;
        font-family: "Pretendard-Bold";
        font-size: 12px;
        line-height: normal;
        text-transform: uppercase;
        color: #fff;
        text-decoration: none;
      }
    }
    .cardTranscript {
      .card-footer {
        justify-content: flex-end !important;
        margin-bottom: -10px;
      }
    }
  }
  .SpeakerBtn {
    &.show {
      &::before {
        // display: none;
        content: "";
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgb(0 0 0 / 6%);
        z-index: 102;
      }
    }
    .dropdown-menu {
      min-width: 200px;
      left: 0 !important;
      padding: 10px 20px;
      .checkbox-container {
        &.active {
          background-color: transparent;
        }
      }
    }
    .dropdwon-scroll {
      height: 80px;
      margin-top: 0;
      padding-right: 0;
    }
    .checkbox-container {
      padding: 12px 0px 11px;
      line-height: 17px;
      .checkmark {
        right: 4px;
      }
    }
    .dropdown-toggle {
      width: 169px;
    }
  } //speakerbtn

  .card-header {
    @media (max-width: 991px) {
      flex-direction: column;
      align-items: flex-start;
    }
    .dropdown-menu {
      min-width: 225px !important;
      padding: 22px 6px 30px 20px;
      .dropdown-header {
        margin-bottom: 20px;
      }
      .dropdown-item {
        min-height: 50px;
        padding: 13px 20px 12px;
        font-weight: 600;
        font-family: "Pretendard-SemiBold";
        font-size: 13px;
        line-height: 16px;
        color: #52626c;
        .icon-txt {
          margin-left: 0;
        }
      }
      .dropdwon-scroll {
        height: 180px;
        &.height-none{
          height: auto;
        }
      }
    }
  }
  .whiteBgFooter {
    @media (max-width: 1199px) {
      flex-direction: column;
      .action-left {
        flex-direction: column;
        width: 100%;
        > span {
          width: 100%;
        }
        .dropdown-toggle {
          width: 100%;
          justify-content: space-between;
        }
        .customUploadBtn {
          margin-left: 0;
          margin-bottom: 0;
          .custom-file-upload {
            width: 100%;
            margin: 10px 0 0;
          }
        }
      }
      .action-right {
        width: 100%;
        .actionBtn {
          margin-left: 0 !important;
          width: 100%;
          margin-top: 10px;
          .btn-primary {
            width: 100%;
          }
        }
      }
    }
  }
  .blue-footer-bg {
    justify-content: space-between !important;
    margin-top: 0;
    background: rgb(37 173 252 / 5%);
    padding: 6px 20px;
    position: relative;
    height: 70px;
    @media (max-width: 991px) {
      min-height: 60px;
      height: auto;
      padding: 12px 20px;
    }
    .sound-row {
      padding: 0 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      @media (max-width: 767px) {
        flex-wrap: wrap;
        padding: 0;
      }
      .timeline {
        position: absolute;
        top: 1px;
        left: 0;
        width: 100%;
      }
      .sound-left-col {
        display: flex;
        @media (max-width: 767px) {
          width: 100%;
          margin-bottom: 20px;
        }
        .sound-details {
          display: flex;
          align-items: center;
          .sound-detail-placeholder {
            width: 30px;
            height: 30px;
            background: #ffffff;
            box-shadow: 2px 5px 15px rgba(0, 0, 0, 0.05);
            border-radius: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
        .detail-txt {
          margin-left: 10px;
          .sound-name {
            font-style: normal;
            font-weight: 600;
            font-family: "Pretendard-SemiBold";
            font-size: 12px;
            line-height: 15px;
            color: #1b1726;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 200px;
            display: block;
          }
          .sound-format {
            margin-top: 1px;
            font-style: normal;
            font-weight: 400;
            font-size: 11px;
            line-height: 13px;
            color: #52626c;
          }
        }
        .detail-txt {
          display: flex;
          flex-direction: column;
        }
      }
      .sound-center-col {
        display: flex;
        align-items: center;
        .music-forward,
        .music-pause,
        .music-backward {
          cursor: pointer;
        }
        .music-pause {
          margin-left: 30px;
          margin-right: 30px;
          @media (max-width: 767px) {
            margin-left: 10px;
            margin-right: 10px;
          }
        }
      }
      .sound-right-col {
        .RecordSoundWrap {
          .StartOverBtn {
            background: rgb(82 98 108 / 5%);
            border-radius: 5px;
            height: 30px;
            width: 101px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-style: normal;
            font-weight: 700;
            font-family: "Pretendard-Bold";
            font-size: 12px;
            line-height: 15px;
            text-align: center;
            text-transform: uppercase;
            color: #52626c;
            border-width: 0;
          }
        }
      }
    }
  }

  .UseMicrophoneBtn {
    width: 164px;
    height: 30px;
    background: rgb(82 98 108 / 5%);
    border-width: 0;
    border-radius: 5px;
    font-style: normal;
    font-weight: 700;
    font-family: "Pretendard-Bold";
    font-size: 12px;
    line-height: 15px;
    text-transform: uppercase;
    color: #52626c;
    .btn-txt {
      margin-left: 10px;
    }
    &:hover {
      color: #52626c;
      background: rgb(82 98 108 / 5%);
    }
  }
  .summaryCardAEmptyBox {
    min-height: 223px !important;
  }
}

// toast Msg Start
.toastMsg {
  width: 100%;
  max-width: 500px;
  height: 99px;
  background: #ffffff;
  border: 1px solid #dfdfdf;
  box-shadow: 2px 5px 15px rgb(0 0 0 / 5%);
  border-radius: 5px;
  display: none;
  align-items: center;
  justify-content: flex-start;
  padding-left: 20px;
  padding-right: 30px;
  position: fixed;
  z-index: 105;
  right: 20px;
  bottom: 20px;
  @media (max-width: 767px) {
    width: 90%;
    bottom: 20px;
  }
  &.show {
    display: flex;
  }

  .toastWrap {
    position: relative;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
  }
  .alertContent {
    margin-left: 20px;
    margin-right: auto;
    .alert-title {
      font-style: normal;
      font-weight: 700;
      font-family: "Pretendard-Bold";
      font-size: 15px;
      line-height: 18px;
      color: #000000;
      display: block;
      margin-bottom: 5px;
    }
    .alert-txt {
      display: block;
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 16px;
      color: #52626c;
    }
  }
  .alertClose {
    img {
      cursor: pointer;
    }
  }
}

.stop-button {
  margin-left: auto !important;
}
// toast Msg End

// dots loader

.dot-elastic {
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
  animation: dotElastic 1s infinite linear;
}

.dot-elastic::before,
.dot-elastic::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
}

.dot-elastic::before {
  left: -15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
  animation: dotElasticBefore 1s infinite linear;
}

.dot-elastic::after {
  left: 15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
  animation: dotElasticAfter 1s infinite linear;
}

@keyframes dotElasticBefore {
  0% {
    transform: scale(1, 1);
  }
  25% {
    transform: scale(1, 1.5);
  }
  50% {
    transform: scale(1, 0.67);
  }
  75% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}

@keyframes dotElastic {
  0% {
    transform: scale(1, 1);
  }
  25% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1, 1.5);
  }
  75% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}

@keyframes dotElasticAfter {
  0% {
    transform: scale(1, 1);
  }
  25% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1, 0.67);
  }
  75% {
    transform: scale(1, 1.5);
  }
  100% {
    transform: scale(1, 1);
  }
}

/**
 * ==============================================
 * Dot Pulse
 * ==============================================
 */
.dot-pulse {
  position: relative;
  left: -9999px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
  box-shadow: 9999px 0 0 -5px #9880ff;
  animation: dotPulse 1.5s infinite linear;
  animation-delay: 0.25s;
}

.dot-pulse::before,
.dot-pulse::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
}

.dot-pulse::before {
  box-shadow: 9984px 0 0 -5px #9880ff;
  animation: dotPulseBefore 1.5s infinite linear;
  animation-delay: 0s;
}

.dot-pulse::after {
  box-shadow: 10014px 0 0 -5px #9880ff;
  animation: dotPulseAfter 1.5s infinite linear;
  animation-delay: 0.5s;
}

@keyframes dotPulseBefore {
  0% {
    box-shadow: 9984px 0 0 -5px #9880ff;
  }
  30% {
    box-shadow: 9984px 0 0 2px #9880ff;
  }
  60%,
  100% {
    box-shadow: 9984px 0 0 -5px #9880ff;
  }
}

@keyframes dotPulse {
  0% {
    box-shadow: 9999px 0 0 -5px #9880ff;
  }
  30% {
    box-shadow: 9999px 0 0 2px #9880ff;
  }
  60%,
  100% {
    box-shadow: 9999px 0 0 -5px #9880ff;
  }
}

@keyframes dotPulseAfter {
  0% {
    box-shadow: 10014px 0 0 -5px #9880ff;
  }
  30% {
    box-shadow: 10014px 0 0 2px #9880ff;
  }
  60%,
  100% {
    box-shadow: 10014px 0 0 -5px #9880ff;
  }
}

/**
 * ==============================================
 * Dot Flashing
 * ==============================================
 */
.dot-flashing {
  position: relative;
  width: 6px;
  height: 6px;
  border-radius: 5px;
  background-color: #52626c1a;
  color: #52626c1a;
  animation: dotFlashing 1s infinite linear alternate;
  animation-delay: 0.5s;
}

.dot-flashing::before,
.dot-flashing::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
}

.dot-flashing::before {
  left: -10px;
  width: 6px;
  height: 6px;
  border-radius: 5px;
  background-color: #52626c1a;
  color: #52626c1a;
  animation: dotFlashing 1s infinite alternate;
  animation-delay: 0s;
}

.dot-flashing::after {
  left: 10px;
  width: 6px;
  height: 6px;
  border-radius: 5px;
  background-color: #52626c1a;
  color: #52626c1a;
  animation: dotFlashing 1s infinite alternate;
  animation-delay: 1s;
}

@keyframes dotFlashing {
  0% {
    background-color: #25adfc;
  }
  50%,
  100% {
    background-color: #52626c1a;
  }
}
@keyframes dotFlashing2 {
  0% {
    background-color: #fff;
  }
  50%,
  100% {
    background-color: rgb(255 255 255 / 5%);
  }
}

/**
 * ==============================================
 * Dot Collision
 * ==============================================
 */
.dot-collision {
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
}

.dot-collision::before,
.dot-collision::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
}

.dot-collision::before {
  left: -10px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
  animation: dotCollisionBefore 2s infinite ease-in;
}

.dot-collision::after {
  left: 10px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
  animation: dotCollisionAfter 2s infinite ease-in;
  animation-delay: 1s;
}

@keyframes dotCollisionBefore {
  0%,
  50%,
  75%,
  100% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-15px);
  }
}

@keyframes dotCollisionAfter {
  0%,
  50%,
  75%,
  100% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(15px);
  }
}

/**
 * ==============================================
 * Dot Revolution
 * ==============================================
 */
.dot-revolution {
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
}

.dot-revolution::before,
.dot-revolution::after {
  content: "";
  display: inline-block;
  position: absolute;
}

.dot-revolution::before {
  left: 0;
  top: -15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
  transform-origin: 5px 20px;
  animation: dotRevolution 1.4s linear infinite;
}

.dot-revolution::after {
  left: 0;
  top: -30px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
  transform-origin: 5px 35px;
  animation: dotRevolution 1s linear infinite;
}

@keyframes dotRevolution {
  0% {
    transform: rotateZ(0deg) translate3d(0, 0, 0);
  }
  100% {
    transform: rotateZ(360deg) translate3d(0, 0, 0);
  }
}

/**
 * ==============================================
 * Dot Carousel
 * ==============================================
 */
.dot-carousel {
  position: relative;
  left: -9999px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
  box-shadow: 9984px 0 0 0 #9880ff, 9999px 0 0 0 #9880ff, 10014px 0 0 0 #9880ff;
  animation: dotCarousel 1.5s infinite linear;
}

@keyframes dotCarousel {
  0% {
    box-shadow: 9984px 0 0 -1px #9880ff, 9999px 0 0 1px #9880ff,
      10014px 0 0 -1px #9880ff;
  }
  50% {
    box-shadow: 10014px 0 0 -1px #9880ff, 9984px 0 0 -1px #9880ff,
      9999px 0 0 1px #9880ff;
  }
  100% {
    box-shadow: 9999px 0 0 1px #9880ff, 10014px 0 0 -1px #9880ff,
      9984px 0 0 -1px #9880ff;
  }
}

/**
 * ==============================================
 * Dot Typing
 * ==============================================
 */
.dot-typing {
  position: relative;
  left: -9999px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
  box-shadow: 9984px 0 0 0 #9880ff, 9999px 0 0 0 #9880ff, 10014px 0 0 0 #9880ff;
  animation: dotTyping 1.5s infinite linear;
}

@keyframes dotTyping {
  0% {
    box-shadow: 9984px 0 0 0 #9880ff, 9999px 0 0 0 #9880ff,
      10014px 0 0 0 #9880ff;
  }
  16.667% {
    box-shadow: 9984px -10px 0 0 #9880ff, 9999px 0 0 0 #9880ff,
      10014px 0 0 0 #9880ff;
  }
  33.333% {
    box-shadow: 9984px 0 0 0 #9880ff, 9999px 0 0 0 #9880ff,
      10014px 0 0 0 #9880ff;
  }
  50% {
    box-shadow: 9984px 0 0 0 #9880ff, 9999px -10px 0 0 #9880ff,
      10014px 0 0 0 #9880ff;
  }
  66.667% {
    box-shadow: 9984px 0 0 0 #9880ff, 9999px 0 0 0 #9880ff,
      10014px 0 0 0 #9880ff;
  }
  83.333% {
    box-shadow: 9984px 0 0 0 #9880ff, 9999px 0 0 0 #9880ff,
      10014px -10px 0 0 #9880ff;
  }
  100% {
    box-shadow: 9984px 0 0 0 #9880ff, 9999px 0 0 0 #9880ff,
      10014px 0 0 0 #9880ff;
  }
}

/**
 * ==============================================
 * Dot Windmill
 * ==============================================
 */
.dot-windmill {
  position: relative;
  top: -10px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
  transform-origin: 5px 15px;
  animation: dotWindmill 2s infinite linear;
}

.dot-windmill::before,
.dot-windmill::after {
  content: "";
  display: inline-block;
  position: absolute;
}

.dot-windmill::before {
  left: -8.66px;
  top: 15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
}

.dot-windmill::after {
  left: 8.66px;
  top: 15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
}

@keyframes dotWindmill {
  0% {
    transform: rotateZ(0deg) translate3d(0, 0, 0);
  }
  100% {
    transform: rotateZ(720deg) translate3d(0, 0, 0);
  }
}

/**
 * ==============================================
 * Dot Bricks
 * ==============================================
 */
.dot-bricks {
  position: relative;
  top: 8px;
  left: -9999px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
  box-shadow: 9991px -16px 0 0 #9880ff, 9991px 0 0 0 #9880ff,
    10007px 0 0 0 #9880ff;
  animation: dotBricks 2s infinite ease;
}

@keyframes dotBricks {
  0% {
    box-shadow: 9991px -16px 0 0 #9880ff, 9991px 0 0 0 #9880ff,
      10007px 0 0 0 #9880ff;
  }
  8.333% {
    box-shadow: 10007px -16px 0 0 #9880ff, 9991px 0 0 0 #9880ff,
      10007px 0 0 0 #9880ff;
  }
  16.667% {
    box-shadow: 10007px -16px 0 0 #9880ff, 9991px -16px 0 0 #9880ff,
      10007px 0 0 0 #9880ff;
  }
  25% {
    box-shadow: 10007px -16px 0 0 #9880ff, 9991px -16px 0 0 #9880ff,
      9991px 0 0 0 #9880ff;
  }
  33.333% {
    box-shadow: 10007px 0 0 0 #9880ff, 9991px -16px 0 0 #9880ff,
      9991px 0 0 0 #9880ff;
  }
  41.667% {
    box-shadow: 10007px 0 0 0 #9880ff, 10007px -16px 0 0 #9880ff,
      9991px 0 0 0 #9880ff;
  }
  50% {
    box-shadow: 10007px 0 0 0 #9880ff, 10007px -16px 0 0 #9880ff,
      9991px -16px 0 0 #9880ff;
  }
  58.333% {
    box-shadow: 9991px 0 0 0 #9880ff, 10007px -16px 0 0 #9880ff,
      9991px -16px 0 0 #9880ff;
  }
  66.666% {
    box-shadow: 9991px 0 0 0 #9880ff, 10007px 0 0 0 #9880ff,
      9991px -16px 0 0 #9880ff;
  }
  75% {
    box-shadow: 9991px 0 0 0 #9880ff, 10007px 0 0 0 #9880ff,
      10007px -16px 0 0 #9880ff;
  }
  83.333% {
    box-shadow: 9991px -16px 0 0 #9880ff, 10007px 0 0 0 #9880ff,
      10007px -16px 0 0 #9880ff;
  }
  91.667% {
    box-shadow: 9991px -16px 0 0 #9880ff, 9991px 0 0 0 #9880ff,
      10007px -16px 0 0 #9880ff;
  }
  100% {
    box-shadow: 9991px -16px 0 0 #9880ff, 9991px 0 0 0 #9880ff,
      10007px 0 0 0 #9880ff;
  }
}

/**
 * ==============================================
 * Dot Floating
 * ==============================================
 */
.dot-floating {
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
  animation: dotFloating 3s infinite cubic-bezier(0.15, 0.6, 0.9, 0.1);
}

.dot-floating::before,
.dot-floating::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
}

.dot-floating::before {
  left: -12px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
  animation: dotFloatingBefore 3s infinite ease-in-out;
}

.dot-floating::after {
  left: -24px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
  animation: dotFloatingAfter 3s infinite cubic-bezier(0.4, 0, 1, 1);
}

@keyframes dotFloating {
  0% {
    left: calc(-50% - 5px);
  }
  75% {
    left: calc(50% + 105px);
  }
  100% {
    left: calc(50% + 105px);
  }
}

@keyframes dotFloatingBefore {
  0% {
    left: -50px;
  }
  50% {
    left: -12px;
  }
  75% {
    left: -50px;
  }
  100% {
    left: -50px;
  }
}

@keyframes dotFloatingAfter {
  0% {
    left: -100px;
  }
  50% {
    left: -24px;
  }
  75% {
    left: -100px;
  }
  100% {
    left: -100px;
  }
}

/**
 * ==============================================
 * Dot Fire
 * ==============================================
 */
.dot-fire {
  position: relative;
  left: -9999px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
  box-shadow: 9999px 22.5px 0 -5px #9880ff;
  animation: dotFire 1.5s infinite linear;
  animation-delay: -0.85s;
}

.dot-fire::before,
.dot-fire::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
}

.dot-fire::before {
  box-shadow: 9999px 22.5px 0 -5px #9880ff;
  animation: dotFire 1.5s infinite linear;
  animation-delay: -1.85s;
}

.dot-fire::after {
  box-shadow: 9999px 22.5px 0 -5px #9880ff;
  animation: dotFire 1.5s infinite linear;
  animation-delay: -2.85s;
}

@keyframes dotFire {
  1% {
    box-shadow: 9999px 22.5px 0 -5px #9880ff;
  }
  50% {
    box-shadow: 9999px -5.625px 0 2px #9880ff;
  }
  100% {
    box-shadow: 9999px -22.5px 0 -5px #9880ff;
  }
}

/**
 * ==============================================
 * Dot Spin
 * ==============================================
 */
.dot-spin {
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: transparent;
  color: transparent;
  box-shadow: 0 -18px 0 0 #9880ff, 12.72984px -12.72984px 0 0 #9880ff,
    18px 0 0 0 #9880ff, 12.72984px 12.72984px 0 0 rgba(152, 128, 255, 0),
    0 18px 0 0 rgba(152, 128, 255, 0),
    -12.72984px 12.72984px 0 0 rgba(152, 128, 255, 0),
    -18px 0 0 0 rgba(152, 128, 255, 0),
    -12.72984px -12.72984px 0 0 rgba(152, 128, 255, 0);
  animation: dotSpin 1.5s infinite linear;
}

@keyframes dotSpin {
  0%,
  100% {
    box-shadow: 0 -18px 0 0 #9880ff, 12.72984px -12.72984px 0 0 #9880ff,
      18px 0 0 0 #9880ff, 12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0),
      0 18px 0 -5px rgba(152, 128, 255, 0),
      -12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0),
      -18px 0 0 -5px rgba(152, 128, 255, 0),
      -12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0);
  }
  12.5% {
    box-shadow: 0 -18px 0 -5px rgba(152, 128, 255, 0),
      12.72984px -12.72984px 0 0 #9880ff, 18px 0 0 0 #9880ff,
      12.72984px 12.72984px 0 0 #9880ff, 0 18px 0 -5px rgba(152, 128, 255, 0),
      -12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0),
      -18px 0 0 -5px rgba(152, 128, 255, 0),
      -12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0);
  }
  25% {
    box-shadow: 0 -18px 0 -5px rgba(152, 128, 255, 0),
      12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0), 18px 0 0 0 #9880ff,
      12.72984px 12.72984px 0 0 #9880ff, 0 18px 0 0 #9880ff,
      -12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0),
      -18px 0 0 -5px rgba(152, 128, 255, 0),
      -12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0);
  }
  37.5% {
    box-shadow: 0 -18px 0 -5px rgba(152, 128, 255, 0),
      12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0),
      18px 0 0 -5px rgba(152, 128, 255, 0), 12.72984px 12.72984px 0 0 #9880ff,
      0 18px 0 0 #9880ff, -12.72984px 12.72984px 0 0 #9880ff,
      -18px 0 0 -5px rgba(152, 128, 255, 0),
      -12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0);
  }
  50% {
    box-shadow: 0 -18px 0 -5px rgba(152, 128, 255, 0),
      12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0),
      18px 0 0 -5px rgba(152, 128, 255, 0),
      12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0), 0 18px 0 0 #9880ff,
      -12.72984px 12.72984px 0 0 #9880ff, -18px 0 0 0 #9880ff,
      -12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0);
  }
  62.5% {
    box-shadow: 0 -18px 0 -5px rgba(152, 128, 255, 0),
      12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0),
      18px 0 0 -5px rgba(152, 128, 255, 0),
      12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0),
      0 18px 0 -5px rgba(152, 128, 255, 0), -12.72984px 12.72984px 0 0 #9880ff,
      -18px 0 0 0 #9880ff, -12.72984px -12.72984px 0 0 #9880ff;
  }
  75% {
    box-shadow: 0 -18px 0 0 #9880ff,
      12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0),
      18px 0 0 -5px rgba(152, 128, 255, 0),
      12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0),
      0 18px 0 -5px rgba(152, 128, 255, 0),
      -12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0), -18px 0 0 0 #9880ff,
      -12.72984px -12.72984px 0 0 #9880ff;
  }
  87.5% {
    box-shadow: 0 -18px 0 0 #9880ff, 12.72984px -12.72984px 0 0 #9880ff,
      18px 0 0 -5px rgba(152, 128, 255, 0),
      12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0),
      0 18px 0 -5px rgba(152, 128, 255, 0),
      -12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0),
      -18px 0 0 -5px rgba(152, 128, 255, 0), -12.72984px -12.72984px 0 0 #9880ff;
  }
}

/**
 * ==============================================
 * Dot Falling
 * ==============================================
 */
.dot-falling {
  position: relative;
  left: -9999px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
  box-shadow: 9999px 0 0 0 #9880ff;
  animation: dotFalling 1s infinite linear;
  animation-delay: 0.1s;
}

.dot-falling::before,
.dot-falling::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
}

.dot-falling::before {
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
  animation: dotFallingBefore 1s infinite linear;
  animation-delay: 0s;
}

.dot-falling::after {
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
  animation: dotFallingAfter 1s infinite linear;
  animation-delay: 0.2s;
}

@keyframes dotFalling {
  0% {
    box-shadow: 9999px -15px 0 0 rgba(152, 128, 255, 0);
  }
  25%,
  50%,
  75% {
    box-shadow: 9999px 0 0 0 #9880ff;
  }
  100% {
    box-shadow: 9999px 15px 0 0 rgba(152, 128, 255, 0);
  }
}

@keyframes dotFallingBefore {
  0% {
    box-shadow: 9984px -15px 0 0 rgba(152, 128, 255, 0);
  }
  25%,
  50%,
  75% {
    box-shadow: 9984px 0 0 0 #9880ff;
  }
  100% {
    box-shadow: 9984px 15px 0 0 rgba(152, 128, 255, 0);
  }
}

@keyframes dotFallingAfter {
  0% {
    box-shadow: 10014px -15px 0 0 rgba(152, 128, 255, 0);
  }
  25%,
  50%,
  75% {
    box-shadow: 10014px 0 0 0 #9880ff;
  }
  100% {
    box-shadow: 10014px 15px 0 0 rgba(152, 128, 255, 0);
  }
}

/**
 * ==============================================
 * Dot Stretching
 * ==============================================
 */
.dot-stretching {
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
  transform: scale(1.25, 1.25);
  animation: dotStretching 2s infinite ease-in;
}

.dot-stretching::before,
.dot-stretching::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
}

.dot-stretching::before {
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
  animation: dotStretchingBefore 2s infinite ease-in;
}

.dot-stretching::after {
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
  animation: dotStretchingAfter 2s infinite ease-in;
}

@keyframes dotStretching {
  0% {
    transform: scale(1.25, 1.25);
  }
  50%,
  60% {
    transform: scale(0.8, 0.8);
  }
  100% {
    transform: scale(1.25, 1.25);
  }
}

@keyframes dotStretchingBefore {
  0% {
    transform: translate(0) scale(0.7, 0.7);
  }
  50%,
  60% {
    transform: translate(-20px) scale(1, 1);
  }
  100% {
    transform: translate(0) scale(0.7, 0.7);
  }
}

@keyframes dotStretchingAfter {
  0% {
    transform: translate(0) scale(0.7, 0.7);
  }
  50%,
  60% {
    transform: translate(20px) scale(1, 1);
  }
  100% {
    transform: translate(0) scale(0.7, 0.7);
  }
}

/**
 * ==============================================
 * Experiment-Gooey Effect
 * Dot Gathering
 * ==============================================
 */
.dot-gathering {
  position: relative;
  width: 12px;
  height: 12px;
  border-radius: 6px;
  background-color: black;
  color: transparent;
  margin: -1px 0;
  filter: blur(2px);
}

.dot-gathering::before,
.dot-gathering::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
  left: -50px;
  width: 12px;
  height: 12px;
  border-radius: 6px;
  background-color: black;
  color: transparent;
  opacity: 0;
  filter: blur(2px);
  animation: dotGathering 2s infinite ease-in;
}

.dot-gathering::after {
  animation-delay: 0.5s;
}

@keyframes dotGathering {
  0% {
    opacity: 0;
    transform: translateX(0);
  }
  35%,
  60% {
    opacity: 1;
    transform: translateX(50px);
  }
  100% {
    opacity: 0;
    transform: translateX(100px);
  }
}

/**
 * ==============================================
 * Experiment-Gooey Effect
 * Dot Hourglass
 * ==============================================
 */
.dot-hourglass {
  position: relative;
  top: -15px;
  width: 12px;
  height: 12px;
  border-radius: 6px;
  background-color: black;
  color: transparent;
  margin: -1px 0;
  filter: blur(2px);
  transform-origin: 5px 20px;
  animation: dotHourglass 2.4s infinite ease-in-out;
  animation-delay: 0.6s;
}

.dot-hourglass::before,
.dot-hourglass::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  width: 12px;
  height: 12px;
  border-radius: 6px;
  background-color: black;
  color: transparent;
  filter: blur(2px);
}

.dot-hourglass::before {
  top: 30px;
}

.dot-hourglass::after {
  animation: dotHourglassAfter 2.4s infinite cubic-bezier(0.65, 0.05, 0.36, 1);
}

@keyframes dotHourglass {
  0% {
    transform: rotateZ(0deg);
  }
  25% {
    transform: rotateZ(180deg);
  }
  50% {
    transform: rotateZ(180deg);
  }
  75% {
    transform: rotateZ(360deg);
  }
  100% {
    transform: rotateZ(360deg);
  }
}

@keyframes dotHourglassAfter {
  0% {
    transform: translateY(0);
  }
  25% {
    transform: translateY(30px);
  }
  50% {
    transform: translateY(30px);
  }
  75% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(0);
  }
}

/**
 * ==============================================
 * Experiment-Gooey Effect
 * Dot Overtaking
 * ==============================================
 */
.dot-overtaking {
  position: relative;
  width: 12px;
  height: 12px;
  border-radius: 6px;
  background-color: transparent;
  color: black;
  margin: -1px 0;
  box-shadow: 0 -20px 0 0;
  filter: blur(2px);
  animation: dotOvertaking 2s infinite cubic-bezier(0.2, 0.6, 0.8, 0.2);
}

.dot-overtaking::before,
.dot-overtaking::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  width: 12px;
  height: 12px;
  border-radius: 6px;
  background-color: transparent;
  color: black;
  box-shadow: 0 -20px 0 0;
  filter: blur(2px);
}

.dot-overtaking::before {
  animation: dotOvertaking 2s infinite cubic-bezier(0.2, 0.6, 0.8, 0.2);
  animation-delay: 0.3s;
}

.dot-overtaking::after {
  animation: dotOvertaking 1.5s infinite cubic-bezier(0.2, 0.6, 0.8, 0.2);
  animation-delay: 0.6s;
}

@keyframes dotOvertaking {
  0% {
    transform: rotateZ(0deg);
  }
  100% {
    transform: rotateZ(360deg);
  }
}

/**
 * ==============================================
 * Experiment-Gooey Effect
 * Dot Shuttle
 * ==============================================
 */
.dot-shuttle {
  position: relative;
  left: -15px;
  width: 12px;
  height: 12px;
  border-radius: 6px;
  background-color: black;
  color: transparent;
  margin: -1px 0;
  filter: blur(2px);
}

.dot-shuttle::before,
.dot-shuttle::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
  width: 12px;
  height: 12px;
  border-radius: 6px;
  background-color: black;
  color: transparent;
  filter: blur(2px);
}

.dot-shuttle::before {
  left: 15px;
  animation: dotShuttle 2s infinite ease-out;
}

.dot-shuttle::after {
  left: 30px;
}

@keyframes dotShuttle {
  0%,
  50%,
  100% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-45px);
  }
  75% {
    transform: translateX(45px);
  }
}

/**
 * ==============================================
 * Experiment-Emoji
 * Dot Bouncing
 * ==============================================
 */
.dot-bouncing {
  position: relative;
  height: 10px;
  font-size: 10px;
}

.dot-bouncing::before {
  content: "⚽🏀🏐";
  display: inline-block;
  position: relative;
  animation: dotBouncing 1s infinite;
}

@keyframes dotBouncing {
  0% {
    top: -20px;
    animation-timing-function: ease-in;
  }
  34% {
    transform: scale(1, 1);
  }
  35% {
    top: 20px;
    animation-timing-function: ease-out;
    transform: scale(1.5, 0.5);
  }
  45% {
    transform: scale(1, 1);
  }
  90% {
    top: -20px;
  }
  100% {
    top: -20px;
  }
}

/**
 * ==============================================
 * Experiment-Emoji
 * Dot Rolling
 * ==============================================
 */
.dot-rolling {
  position: relative;
  height: 10px;
  font-size: 10px;
}

.dot-rolling::before {
  content: "⚽";
  display: inline-block;
  position: relative;
  transform: translateX(-25px);
  animation: dotRolling 3s infinite;
}

.record-wav {
  width: 93%;
  margin-bottom: 69px;
}

@keyframes dotRolling {
  0% {
    content: "⚽";
    transform: translateX(-25px) rotateZ(0deg);
  }
  16.667% {
    content: "⚽";
    transform: translateX(25px) rotateZ(720deg);
  }
  33.333% {
    content: "⚽";
    transform: translateX(-25px) rotateZ(0deg);
  }
  34.333% {
    content: "🏀";
    transform: translateX(-25px) rotateZ(0deg);
  }
  50% {
    content: "🏀";
    transform: translateX(25px) rotateZ(720deg);
  }
  66.667% {
    content: "🏀";
    transform: translateX(-25px) rotateZ(0deg);
  }
  67.667% {
    content: "🏐";
    transform: translateX(-25px) rotateZ(0deg);
  }
  83.333% {
    content: "🏐";
    transform: translateX(25px) rotateZ(720deg);
  }
  100% {
    content: "🏐";
    transform: translateX(-25px) rotateZ(0deg);
  }
}

/*# sourceMappingURL=three-dots.css.map */

.two-action-btn{
  display: flex;
  align-items: center;
  @media (max-width: 1199px) {
    width: 100%;
  }
  .title-action-btn{
    @media (max-width: 991px) {
      width: 100%;
      .dropdown-toggle{
        width: 100%;
      }
    }
    &:last-child{
      margin-left: 10px;
      @media (max-width: 991px) {
        margin-left: 0;
        margin-top: 10px;
      }
    }
  }
  @media (max-width: 991px) {
    flex-direction: column;
    align-items: flex-start;
    margin-top: 0;
    width: 100%;
    .starOverbtnWithLanguage{
      width: 100%;
    }
    .SpeakerBtn{
      width: 100%;
      .dropdown-toggle{
        width: 100%;
      }
    }
  }
}

.btnstartOver {
  width: 101px;
  height: 30px;
  background: rgb(82 98 108 / 5%);
  border-width: 0;
  border-radius: 5px;
  font-style: normal;
  font-weight: 700;
  font-family: "Pretendard-Bold";
  font-size: 12px;
  line-height: 15px;
  text-transform: uppercase;
  color: #52626c;
  margin-left: 10px;
  @media (max-width: 1199px) {
    margin-top: 10px;
    margin-left: 0;
    width: 100%;
  }
  .btn-txt {
    margin-left: 0;
  }
  &:hover,&:focus,&:active {
    color: #52626c !important;
    background: rgb(82 98 108 / 5%) !important;
  }
}





.uploaded-file-row--error {
  margin-top: 20px;
  height: 70px;
  background: rgb(255 76 76 / 5%);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  padding: 20px;
  @media (max-width: 767px) {
    flex-direction: column;
    height: 60px;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .uploaded-file-content {
    display: flex;
    align-items: center;

    .fileName {
      margin-left: 0;
      font-style: normal;
      font-weight: 600;
      font-family: "Pretendard-SemiBold";
      font-size: 14px;
      line-height: 17px;
      color: #FF4C4C;
      @media (max-width: 767px) {
        font-size: 12px;
        line-height: 15px;
      }
    }
  } 
}



.tryExampleRTS{
  .dropdwon-scroll {
    height: 180px;
    &.height-none{
      height: auto;
    }
  }
  .dropdown-menu{
    min-width: 225px !important;
    padding: 22px 6px 30px 20px !important;
    .dropdown-item{
      height: 50px;
      min-height: 50px;
    }
  }
}