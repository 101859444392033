:root {
  // Custom properties goes here
  --accent: #25ADFC;
  --size: 2.3rem;
  --duration: 1s;
}
.spinner {
  margin: auto;
  width: var(--size);
  aspect-ratio: 1;
  // display: grid;
  mask: conic-gradient(from 22deg, #0001, #000);
  animation: spin var(--duration) steps(8) infinite;
  display: none !important;
  &,
  &:before {
    --gradient: linear-gradient(var(--accent) 0 0);
    background: var(--gradient) 50%/34% 8% space no-repeat,
      var(--gradient) 50%/8% 34% no-repeat space;
  }

  &:before {
    content: "";
    transform: rotate(45deg);
  }
}

@keyframes spin {
  to {
    transform: rotate(1turn);
  }
}
