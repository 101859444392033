@import "../../assets/scss/animation.scss";
.confirmModal {
  animation-duration: 0.5s;
  -webkit-animation-duration: 0.5s;
  animation-fill-mode: both;
  -webkit-animation-fill-mode: both;
  -webkit-animation-name: slideIn;
  animation-name: slideIn;
  .modal-header {
    padding: 0;
    border-width: 0;
    border-radius: 0;
  }
  .modal-title {
    font-weight: 700;
    font-family: 'Pretendard-Bold';
    font-size: 20px;
    line-height: 24px;
    color: #1b1726;
  }
  .modal-dialog {
    @media (min-width: 576px) {
      max-width: 350px;
    }
  }
  .modal-content {
    border-radius: 5px;
    padding: 30px;
    @media (max-width: 359px) {
      padding: 30px 15px;
    }
  }
  .btn-close {
    display: none;
  }
  .modal-body {
    padding: 0;
    p {
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: #52626c;
      margin-bottom: 0;
      margin-top: 10px;
    }
  }
  .modal-footer {
    padding: 0;
    margin-top: 30px;
    border-width: 0;
    justify-content: space-between;
    .btnNo {
      width: 140px;
      height: 40px;
      background-color: rgb(82 98 108 / 5%);
      border-radius: 5px;
      border-width: 0;
      font-weight: 700;
      font-family: 'Pretendard-Bold';
      font-size: 12px;
      line-height: normal;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      text-transform: uppercase;
      color: #52626c;
      margin: 0;
    }
    .btnYes {
      width: 140px;
      height: 40px;
      background-color: rgb(255 76 76 / 10%);
      border-radius: 5px;
      border-width: 0;
      font-weight: 700;
      font-family: 'Pretendard-Bold';
      font-size: 12px;
      line-height: normal;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      text-transform: uppercase;
      color: #ff4c4c;
      margin: 0;
    }
    .btn{
      width: calc(50% - 5px);
    }
  }
}
