@import "../../assets/scss/animation.scss";
.SignInModal {
  animation-duration: 0.5s;
  -webkit-animation-duration: 0.5s;
  animation-fill-mode: both;
  -webkit-animation-fill-mode: both;
  -webkit-animation-name: slideIn;
  animation-name: slideIn;
  .modal-header {
    padding: 0;
    border-width: 0;
    border-radius: 0;
  }
  .modal-title {
    margin: 0 0 5px;
    font-style: normal;
    font-weight: 700;
    font-family: "Pretendard-Bold";
    font-size: 20px;
    line-height: 24px;
    color: #1b1726;
  }
  .modal-body {
    padding: 0;
    p {
      margin: 0 0 30px;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: #52626c;
    }
  }
  .btn-close {
    width: 20px;
    height: 20px;
    color: #52626c;
    border-radius: 0;
    margin: 0;
    position: absolute;
    top: 32px;
    right: 30px;
    padding: 0;
    font-size: 0;
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    &:focus {
      outline: 0;
      box-shadow: none;
    }
    &::before {
      display: block;
      background: url("../../assets/images/ic-modal-close.svg");
      width: 11px;
      height: 11px;
      background-repeat: no-repeat;
      content: "";
      background-size: 100% 100%;
      background-position: center center;
    }
    // .a{
    //   ic-modal-close.svg
    // }
  }
  .modal-dialog {
    @media (min-width: 576px) {
      max-width: 460px;
    }
  }
  .modal-content {
    border-radius: 5px;
    padding: 30px;
    border-width: 0;
    box-shadow: 2px 5px 15px rgba(0, 0, 0, 0.05);
    @media (max-width: 359px) {
      padding: 30px 15px;
    }
  }
  .modal-footer {
    padding: 0;
    margin-top: 30px;
    border-width: 0;
    justify-content: space-between;
  }
  &.ForgotPasswordModal {
    .modal-body {
      p {
        max-width: 400px;
        margin-bottom: 32px;
      }
    }
    .BacktoSignIn {
      margin-top: 15px;
      text-align: center;
      line-height: normal;
      height: 15px;
      span {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        text-decoration-line: underline;
        color: #25adfc;
        display: inline-block;
        cursor: pointer;
      }
      a {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        text-decoration-line: underline;
        color: #25adfc;
      }
    }
  }

  &.EnterVerificationModal {
    .modal-content {
      background: #fafafa;
    }
    .modal-body {
      p {
        margin-bottom: 40px;
        .userEmailId {
          margin-top: 5px;
          font-weight: 600;
          font-family: "Pretendard-SemiBold";
          font-size: 14px;
          line-height: 17px;
          color: #1b1726;
        }
      }
    }
  }
  &.ResetPasswordModal {
    .modal-body {
      p {
        line-height: 17px;
      }
    }
  }
}
