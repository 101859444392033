.breadcrumb {
  margin-bottom: 10px;
  .breadcrumb-item {
    font-weight: 400;
    font-size: 12px;
    color: #52626c;
    display: flex;
    align-items: center;
    line-height: normal;
    + .breadcrumb-item{
      padding-left: 0;
    }
    &.active {
      margin-left: 0;
      line-height: 15px;
      &::before {
        background: url("../../assets/images/ic-breadcrumb-arrow.svg");
        width: 15px;
        height: 15px;
        background-repeat: no-repeat;
        content: "";
        background-size: 100% 100%;
        background-position: center center;
        margin-left: 2px;
        margin-right: 2px;
      }
    }
    a {
      font-weight: 400;
      font-size: 11px;
      line-height: 13px;
      margin-top: 0;
      text-decoration-line: underline;
      color: #25adfc;
    }
  }
}
