h1 {
  font-weight: 900;
  text-align: left;
  color: #1b1726;
  margin: 0;
  font-size: 30px;
  line-height: 36px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  font-family: 'Pretendard-Black';
  @media (max-width: 991px) {
    font-size: 22px;
    line-height: 27px;
  }
  .btnDemo {
    width: 68px;
    height: 25px;
    background: rgb(4 217 178 / 10%);
    border-radius: 25px;
    font-style: normal;
    font-weight: 700;
    font-family: 'Pretendard-Bold';
    font-size: 12px;
    line-height: normal;
    display: flex;
    align-items: center;
    text-align: center;
    text-transform: uppercase;
    color: #04d9b2;
    justify-content: center;
    margin-left: 15px;
    @media (max-width: 991px) {
      margin-left: 10px;
    }
  }
}
.two-tag-wrap{
  display: flex;
}
.KoreanOnlyTag{
  width: 116px;
  height: 25px;
  background: rgb(37 173 252 / 10%);
  border-radius: 25px;
  font-style: normal;
  font-weight: 700;
  font-family: 'Pretendard-Bold';
  font-size: 12px;
  line-height: normal;  
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  color: #25ADFC;
  justify-content: center;
  margin-left: 15px;
  display: none;
  @media (min-width: 768px) {
    display: flex;
  }
  &.KoreanOnlyTagMobile{
    display: flex;
    margin-left: 0;
    margin-top: 12px;
    @media (min-width: 768px) {
      display: none;
    } 
  }
}

.heading-txt {
  max-width: 750px;
  width: 100%;
  margin-top: 5px;
  margin-bottom: 40px;
  font-weight: 400;
  font-size: 15px;
  line-height: 30px;
  color: #52626c;
  @media (max-width: 991px) {
    font-size: 12px;
    line-height: 24px;
    margin-bottom: 30px;
  }
  .d-block{
    line-height: normal;
    &:last-child{
      line-height: 30px;
    }
  }
}
