.ribben-box {
  position: absolute;
  width: 126px;
  height: 36px;
  right: -5px;
  top: 15px;
  z-index: 2;
  @media (max-width: 767px) {
    right: -3px;
  }
}
.ribbenTxt {
  font-weight: 700;
  font-family: 'Pretendard-Bold';
  font-size: 12px;
  line-height: 15px;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  position: absolute;
  top: 12px;
  z-index: 3;
  left: 24px;
}
.dashboardContainer {
  @media (max-width: 991px) {
    padding-right: 20px;
    padding-left: 20px;
    padding-top: 90px;
  }
  h1 {
    font-weight: 900;
    font-family: 'Pretendard-Black';
    text-align: center;
    color: #1b1726;
    // max-width: 667px;
    width: 100%;
    margin: 80px auto;
    font-size: 40px;
    line-height: 55px;
    text-transform: none;
    align-items: center;
    justify-content: center;
    @media (max-width: 991px) {
      margin: 50px auto;
      font-size: 20px;
      line-height: 32px;
      max-width: 335px;
    }
  }
}
.cardViewRow {
  margin-left: -15px;
  margin-right: -15px;
  display: flex;
  flex-wrap: wrap;
  .mb-30 {
    padding-left: 15px;
    padding-right: 15px;
  }
  .card {
    background: #ffffff;
    box-shadow: 2px 5px 15px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    padding: 30px;
    height: 100%;
    border-width: 0;
    cursor: pointer;
    transition: all 500ms ease 0s !important;
    min-height: 299px;
    @media (max-width: 767px) {
      min-height: 297px;
    }
    &:hover {
      box-shadow: 2px 5px 15px rgba(0, 0, 0, 0.15);
    }
    .ImgContainer {
      height: 45px;
    }
    .card-img {
      margin-right: auto;
      width: auto;
      height: auto;
      max-width: 100%;
    }
    .card-body {
      padding: 0;
      text-align: right;
    }
    .card-title {
      font-weight: 700;
      font-family: 'Pretendard-Bold';
      font-size: 20px;
      line-height: 24px;
      color: #1b1726;
      margin-top: 20px;
      margin-bottom: 10px;
      text-align: left;
      @media (max-width: 991px) {
        font-size: 20px;
        line-height: 24px;
      }
    }
    .card-text {
      text-align: left;
      font-weight: 400;
      font-size: 14px;
      line-height: 26px;
      color: #52626c;
      margin-bottom: 6px;
      @media (max-width: 991px) {
        font-size: 14px;
        line-height: 26px;
        margin-bottom: 30px;
      }
    }
    .action-btn {
      text-align: right;
      margin-top: auto;
    }
    .btn {
      &.demoBtn {
        border-radius: 5px;
        background: rgb(37 173 252 / 10%) !important;
        height: 30px;
        width: 100px;
        font-weight: 700;
        font-family: 'Pretendard-Bold';
        font-size: 12px;
        line-height: 15px;
        text-transform: uppercase;
        color: #25adfc;
        border-width: 0;
        @media (max-width: 991px) {
          width: 100%;
        }
      }
    }
  } //card
}
.bannerBox {
  height: 200px;
  margin-top: 70px;
  padding: 38px 15px;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  z-index: 1;
  .bannerImage {
    width: 100%;
    height: 200px;
    position: absolute;
    top: 0;
    left: 0;
    max-width: 100%;
    z-index: -1;
    @media (max-width: 767px) {
      display: none;
    }
    &.mobileView{
      display: none;
      @media (max-width: 767px) {
        display: block;
      }  
    }
  }
  h2 {
    font-weight: 900;
    font-family: "Pretendard-Bold";
    font-size: 28px;
    line-height: 35px;
    text-align: center;
    text-transform: uppercase;
    color: #ffffff;
    margin: 0 auto 20px;
    // max-width: 305px;
    width: 100%;
    @media (max-width: 991px) {
      margin: 0 auto 15px;
      font-size: 20px;
      line-height: 30px;
      max-width: 244px;
      width: 100%;
    }
  }
  .emailUsBtn {
    border-width: 0;
    min-width: 235px;
    min-height: 35px;
    height: 35px;
    background: #ffffff;
    border-radius: 5px;
    font-weight: 700;
    font-family: 'Pretendard-Bold';
    font-size: 11px;
    text-transform: uppercase;
    color: #1b1726;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: normal;
    cursor: pointer;
    @media (max-width: 991px) {
      min-width: 260px;
      height: 45px;
      min-height: 45px;
      font-weight: 600;
      font-family: 'Pretendard-SemiBold';
      font-size: 12px;
    }
  }
}
