.background {
  position: absolute;
  top: 8rem;
  right: -50px;
  bottom: -50px;
  left: -50px;
  /* background-image: url("background.jpg"); */
  background-color: red;
  background-position: center center;
  background-size: cover;
  filter: blur(15px);
}

.track-title {
  position: absolute;

  top: 200px;
  right: 0;
  left: 0;

  color: white;
  font-family: Calibri;
  font-size: 100px;
  text-align: center;
}

.visualizer-container {
  position: relative;
  height: 14rem;
  overflow: hidden;
}
.visualizer-container-div {
  position: absolute;
  bottom: 50%;
  right: 0;
  left: 0;
  text-align: center;
}

.visualizer-container__bar {
  display: inline-block;
  position: relative;
  top: 50%;
  background: #25adfc;
  margin: 0 3px;
  border-radius: 0px 0px 30px 30px;
  width: calc(2.631578% - 6px);
  transform: translateY(0%) rotate(-180deg);
  // min-width: 3px;
}
.visualizer-container__bar.add {
  transform: translateY(100%) rotate(-180deg);
  display: inline-block;
  border-radius: 30px 30px 0px 0px;
  top: -1px;
}
.audio-player {
  --player-button-width: 3em;
  --sound-button-width: 2em;
  --space: 0.5em;
  margin-left: -10px;
  margin-right: -10px;
  background: rgb(82 98 108 / 10%);
  position: relative;
  bottom: -7px;
  .controls {
    margin: 0;
  }
}

.icon-container {
  width: 100%;
  height: 100%;
  background-color: #de5e97;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.audio-icon {
  width: 90%;
  height: 90%;
}

.controls {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin-top: 10px;
  &:hover .toolTipTimer {
    opacity: 1;
  }
}

.player-button {
  background-color: transparent;
  border: 0;
  width: var(--player-button-width);
  height: var(--player-button-width);
  cursor: pointer;
  padding: 0;
}

.timeline {
  -webkit-appearance: none;
  width: calc(
    100% -
      (var(--player-button-width) + var(--sound-button-width) + var(--space))
  );
  height: 3px;
  background: rgb(82 98 108 / 10%);
  border-radius: 5px;
  background-size: 0% 100%;
  background-image: linear-gradient(#25adfc, #25adfc);
  background-repeat: no-repeat;
  margin-right: var(--space);
  min-width: 100%;
}

.timeline::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  cursor: pointer;
  opacity: 1;
  transition: all 0.1s;
  background-color: #25adfc;
  border: 2px solid #ffffff;
  box-shadow: 2px 5px 15px rgba(0, 0, 0, 0.2);
}

.timeline::-moz-range-thumb {
  -webkit-appearance: none;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  cursor: pointer;
  opacity: 0;
  transition: all 0.1s;
  background-color: #25adfc;
}

.timeline::-ms-thumb {
  -webkit-appearance: none;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  cursor: pointer;
  opacity: 0;
  transition: all 0.1s;
  background-color: #25adfc;
}

.timeline::-webkit-slider-thumb:hover {
  background-color: #25adfc;
}

.timeline:hover::-webkit-slider-thumb {
  opacity: 1;
}

.timeline::-moz-range-thumb:hover {
  background-color: #25adfc;
}

.timeline:hover::-moz-range-thumb {
  opacity: 1;
}

.timeline::-ms-thumb:hover {
  background-color: #25adfc;
}

.timeline:hover::-ms-thumb {
  opacity: 1;
}

.timeline::-webkit-slider-runnable-track {
  -webkit-appearance: none;
  box-shadow: none;
  border: none;
  background: transparent;
}

.timeline::-moz-range-track {
  -webkit-appearance: none;
  box-shadow: none;
  border: none;
  background: transparent;
}

.timeline::-ms-track {
  -webkit-appearance: none;
  box-shadow: none;
  border: none;
  background: transparent;
}

.sound-button {
  background-color: transparent;
  border: 0;
  width: var(--sound-button-width);
  height: var(--sound-button-width);
  cursor: pointer;
  padding: 0;
}
.soundBar {
  width: 50px;
  height: 7px;
  border-radius: 20px;
  background: red;
}

.toolTipTimer {
  position: absolute;
  left: auto;
  right: auto;
  bottom: 12px;
  width: 46px;
  height: 15px;
  font-style: normal;
  font-weight: 700;
  font-family: "Pretendard-Bold";
  font-size: 11px;
  line-height: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  background: #1b1726;
  border-radius: 100px;
  opacity: 0;
}

.volume-control {
  position: absolute;
  right: -6px;
  bottom: -16px;
  width: 36px;
  height: 90px;
  background: #fff;
  box-shadow: 2px 5px 15px rgb(0 0 0 / 5%);
  border-radius: 5px;
  z-index: 2;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  @media (max-width: 1180px) {
    // display: none;
  }
  .volume-control--Box {
    display: flex;
    transform: rotateZ(-90deg);
    align-items: center;
    position: relative;
  }
  .timeline {
    height: 4px;
    min-width: 70px;
    position: absolute;
    right: -43px;
    bottom: 8px;
    z-index: 3;
  }
}

div#waveform > wave {
  display: none !important;
}

div#waveform > wave:last-child {
  display: block !important;
}
region.wavesurfer-region handle {
  display: none;
}
region.wavesurfer-region {
  z-index: 0 !important;
}
