.copyrightSection {
  background: #ffffff;
}
.copyrightContainer {
  background: #ffffff;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 991px) {
    padding-right: 0;
    padding-left: 0;
    height: auto;
    flex-direction: column;
    align-items: flex-start;
    .links-group-left {
      flex-wrap: wrap;
      margin-left: 0px !important;
      margin-right: 0px !important;
      padding: 20px 10px 8px;
      width: 100%;
    }
    .links-group-right {
      padding: 0 20px 20px 20px;
      margin-left: -12px !important;
      margin-right: -12px !important;
      width: calc(100% - -24px);
      border-top: 0 solid rgb(82 98 108 / 10%);
    }
  }
  .copyright-txt {
    margin: 0;
    font-weight: 500;
    font-family: 'Pretendard-Medium';
    font-size: 14px;
    line-height: 17px;
    color: #52626c;
    @media (max-width: 991px) {
      font-size: 12px;
      line-height: 15px;
    }
  }
  .list-unstyled {
    margin: 0;
    &.links-group-left {
      display: flex;
      .list-item {
        margin-right: 50px;
        line-height: normal;
        @media (max-width: 991px) {
          margin-right: 0;
          margin-bottom: 12px;
          width: 33.333333%;
        }
        &:last-child {
          margin-right: 0;
        }
        .link-btn {
          font-weight: 500;
          font-family: 'Pretendard-Medium';
          font-size: 14px;
          line-height: 17px;
          color: #52626c;
          text-decoration: none;
          @media (max-width: 991px) {
            font-size: 12px;
            line-height: 15px;  
          }
          &:hover {
            color: #52626c;
          }
        }
      }
    }
  }
}
