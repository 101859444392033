.sectionBanner {
  background-color: #fff;
  margin-top: 45px;
  @media (max-width: 767px) {
    margin-top: 0;
  }
  + .power--Banner {
    .bannerBox {
      margin-top: 0;
    }
  }
}
.companiesGrowingContainer {
  @media (max-width: 767px) {
    padding-right: 15px;
    padding-left: 15px;
    padding-top: 0;
    margin-top: 0;
  }

  .growingBusinessTxt {
    text-align: center;
    margin-top: 50px;
    margin-bottom: 10px;
    font-weight: 500;
    font-family: 'Pretendard-Medium';
    font-size: 16px;
    line-height: 13px;
    color: #52626c;
    @media (max-width: 991px) {
      margin-top: 50px;
      margin-bottom: 20px;
      max-width: 199px;
      margin-left: auto;
      margin-right: auto;
      line-height: 19px;
    }
    span {
      color: #25adfc;
    }
  }
}
.companiesNameContainer {
  overflow: auto;
  margin-bottom: 45px;
  @media (max-width: 991px) {
    margin-bottom: 18px;
    max-width: 100%;
  }
  .list-unstyled {
    display: table;
    width: 100%;
    margin-bottom: 0;
    .list-item {
      width: 20%;
      display: table-cell;
      text-align: center;
      img {
        @media (min-width: 768px) and (max-width: 1199px) {
          max-width: 100%;
        }
      }
    }
  }
}

.slider-for-desktop-view {
  display: none !important;
  @media (min-width: 768px) {
    display: table !important;
  }
}
.slider-mobile-view {
  display: block !important;
  margin-top: -2px;
  @media (min-width: 768px) {
    display: none !important;
  }
  .carousel-item {
    .carousel-item-Img {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
    }
    img {
      max-width: 100%;
    }
  }
}
