@import "../../assets/scss/animation.scss";
.SignUpModal {
  .modal-content {
    padding-right: 0;
  }
  p{
    padding-right: 30px;
  }
  .form-group {
    width: 100%;
    max-width: calc(100% - 30px);
    @media (min-width: 768px) {
      max-width: 400px;
      width: 100%;
    }
    .form-group{
      max-width: 100% !important;
      width: 100% !important;
    }
  }
  .action-btn{
    padding-right: 30px;
  }
  .agreeConditions {
    margin-top: 30px;
    margin-bottom: -8px;
    padding-right: 30px;
    span {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      color: #52626c;
      display: block;
      a {
        text-decoration: underline;
        color: #25adfc;
      }
    }
  }
}
.CustomScrollBarWrap {
  .scrollBar {
    > div {
      background-color: inherit;
    }
  }
}
