.TextSpeechContainer {
  .cardSummary {
    overflow: hidden;
  }
  .heading-txt {
    max-width: 1100px;
  }
  .AutoSummarizationCard {
    .card-footer {
      .action-left {
        margin-right: auto;
      }
      .action-right {
        display: flex;
        align-items: center;
      }
      .clearBtn {
        width: 60px;
        height: 30px;
        background: transparent;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-width: 0;
        font-weight: 600;
        font-family: 'Pretendard-SemiBold';
        font-size: 12px;
        line-height: normal;
        text-transform: uppercase;
        color: rgb(82 98 108 / 100%);
        text-decoration: none;
      }
      .submitBtn {
        width: 90px;
        height: 30px;
        background: #25adfc;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 10px;
        font-weight: 700;
        font-family: 'Pretendard-Bold';
        font-size: 12px;
        line-height: normal;
        text-transform: uppercase;
        color: #fff;
        text-decoration: none;
      }
    }
  }
  .SpeakerBtn {
    &.show {
      &::before {
        // display: none;
        content: "";
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgb(0 0 0 / 6%);
        z-index: 102;
      }
    }
    .dropdown-menu {
      min-width: 300px;
      left: 0 !important;
      padding: 10px 20px;
    }
    .dropdwon-scroll {
      height: 210px;
      margin-top: 0;
      padding-right: 0;
    }
    .checkbox-container {
      padding: 10px 0px 9px;
      .checkmark {
        right: 4px;
      }
    }
    .dropdown-toggle {
      width: 106px;
    }
    &.SpeakerBtnCustom {
      &.customizeWidthHeight{
        .dropdown-menu {
          min-width: 180px;
        }
        .dropdwon-scroll{
          height: 140px;
        }
      }
      .dropdown-toggle {
        text-align: left;
        max-width: 64px;
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        position: relative;
        padding-right: 28px;
        min-width: 124px;
        @media (min-width: 992px) {
          min-width: 150px;
        }
        &::after {
          z-index: 1;
        }
      }
    }
  } //speakerbtn
  div#waveform > wave > wave {
    border-right-width: 0 !important;
    &::after {
      background: url("../../assets/images/wevebar-stick-2.svg");
      width: 12px;
      height: 110px;
      background-repeat: no-repeat;
      content: "";
      background-position: center center;
      position: absolute;
      bottom: 44px;
      right: -4px;
      z-index: 2;
      display: block;
      background-size: 34px 110px;
    }
  }
}
