.solveYourProblemsMediaImage {
  @media (max-width: 991px) {
    margin-top: 16px;
  }
  img {
    width: auto;
    height: auto;
    max-width: 100%;
  }
}
.solveYourProblemsContainer {
  .overview-badge {
    text-align: center;
    margin: 0;
    @media (max-width: 991px) {
      margin-top: 70px;
      margin-bottom: 20px;
    }
    .badgeView {
      width: 100px;
      height: 35px;
      background: rgb(37 173 252 / 10%);
      border-radius: 100px;
      font-weight: 900;
      font-family: 'Pretendard-Black';
      font-size: 13px;
      line-height: normal;
      text-transform: uppercase;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #25adfc;
      margin: 0 auto;
    }
  }
  h2 {
    max-width: 600px;
    font-weight: 900;
    font-family: 'Pretendard-Black';
    font-size: 30px;
    line-height: 45px;
    text-align: center;
    text-transform: uppercase;
    color: #1b1726;
    margin: 20px auto 32px;
    @media (max-width: 991px) {
      font-size: 18px;
      line-height: 25px;
      max-width: 335px;
      margin-bottom: 20px;
    }
  }
  .solveYourProblemsCard {
    .list-group {
      border-radius: 0;
      padding: 0 60px 0 0;
      @media (max-width: 991px) {
        padding-right: 0;
      }
      .list-group-item {
        margin-top: 60px;
        padding: 0;
        background-color: transparent;
        border-width: 0;
        border-radius: 0;
        display: flex;
        @media (max-width: 991px) {
          margin-top: 35px;
        }
        .item-image {
          width: 90px;
          margin-top: -10px;
          @media (min-width: 1200px) {
            margin-left: -12px;
          }
        }
        .item-content {
          display: flex;
          flex-direction: column;
          strong {
            font-weight: 700;
            font-family: 'Pretendard-Bold';
            font-size: 18px;
            line-height: 22px;
            color: #1b1726;
          }
          p {
            margin-top: 5px;
            margin-bottom: 0;
            font-weight: 400;
            font-size: 15px;
            line-height: 30px;
            color: #52626c;
            @media (max-width: 991px) {
              font-size: 13px;
              line-height: 28px;
            }
          }
        }
      }
    }
  }
}
.solveYourProblemsContainerCenter {
  align-items: center;
}
