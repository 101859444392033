@import "~bootstrap/scss/bootstrap";
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import './assets/scss/fonts.scss';

body {
  // font-family: "Inter", sans-serif;
  font-family: 'Pretendard-Regular';
  background: #f8fcff;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

// Custome CSS added Start
.mb-30 {
  margin-bottom: 30px;
}
.cursor {
  cursor: pointer;
}

// Custome CSS added End

.modal-backdrop.show {
  opacity: 0.6 !important;
}

.container {
  @media (min-width: 1200px) {
    max-width: 1140px;
    padding-right: 0;
    padding-left: 0;
  }
}
.btn {
  &:focus {
    box-shadow: none !important;
  }
}
.disabled-btn {
  pointer-events: none;
}
.btn-primary {
  &.disabled-btn {
    color: rgb(255 255 255 / 50%) !important;
  }
}
.btn-primary:disabled,
.btn-primary.disabled {
  opacity: 1;
  color: rgb(255 255 255 / 50%) !important;
}

input:-webkit-autofill {
  box-shadow: 0 0 0 30px transparent inset !important;
  -webkit-box-shadow: 0 0 0 30px transparent inset !important;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s !important;
}
select::-ms-expand {
  display: none;
}
input::-ms-clear,
input::-ms-reveal {
  display: none;
}
input,
select,
textarea {
  vertical-align: middle;
  -webkit-appearance: none;
  border-radius: 0;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

::-webkit-credentials-auto-fill-button {
  visibility: hidden;
  position: absolute;
  right: 0;
}



