.customFormUI {
  .form-group {
    position: relative;
    margin-bottom: 20px;
    &.with-icon {
      .form-control {
        padding-right: 38px;
      }
    }
    .passwordView {
      cursor: pointer;
      z-index: 2;
      position: absolute;
      width: 16px;
      height: 16px;
      margin: auto 0 auto auto;
      left: 0;
      right: 15px;
      bottom: 0;
      top: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 0.5;
      img {
        height: 14.14px;
        width: 14.43px;
      }
      &.eyeShow {
        img {
          height: 12px;
          width: 14.43px;
        }
      }
    }
    .text-muted {
      display: none;
      font-style: normal;
      font-weight: 600;
      font-family: "Pretendard-SemiBold";
      font-size: 10px;
      line-height: 12px;
      color: #ff4c4c !important;
      position: absolute;
      left: 20px;
      bottom: -12px;
      margin: 0;
    }
    &.disabled {
      .form-label {
        color: rgb(82 98 108 / 40%);
      }
      .form-control {
        color: rgb(27 23 38 / 40%);
        pointer-events: none;
        border: 1px solid rgb(82 98 108 / 10%);
        &:focus {
          border: 1px solid rgb(82 98 108 / 10%);
        }
        &::placeholder {
          color: rgb(27 23 38 / 40%);
        }
      }
    }
    &.error {
      .form-control {
        border-color: #ff4c4c;
      }
      .text-muted {
        display: block;
      }
    }
    .form-label,
    label {
      display: flex;
      align-items: center;
      opacity: 1 !important;
      margin: 0;
      font-style: normal;
      font-weight: 500;
      font-family: "Pretendard-Medium";
      font-size: 14px;
      line-height: 15px;
      color: #52626c;
      position: absolute;
      top: 0;
      left: 0;
      padding-left: 20px;
      padding-right: 20px;
      width: 100%;
      span {
        color: #ff4c4c !important;
      }
    }
    .form-control {
      height: 70px;
      border: 1px solid rgb(82 98 108 / 10%);
      border-radius: 5px;
      padding: 20px 20px 17px 20px;
      font-weight: 600;
      font-family: "Pretendard-SemiBold";
      font-size: 14px;
      line-height: 17px;
      color: #1b1726;
      &::placeholder {
        color: red;
      }
      &:focus {
        border: 1px solid #25adfc;
        box-shadow: none;
        + label {
          font-size: 12px;
        }
      }
    }
  }
  .link {
    text-align: right;
    line-height: 15px;
    .ForgotPasswordLink {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      text-decoration-line: underline;
      color: #25adfc;
      cursor: pointer;
    }
  }
  .action-btn {
    margin-top: 30px;
    line-height: normal;
    .btn {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 40px;
      background: #25adfc;
      border-radius: 5px;
      font-style: normal;
      font-weight: 700;
      font-family: "Pretendard-Bold";
      font-size: 12px;
      line-height: 16px;
      text-transform: uppercase;
      color: #ffffff;
      border-width: 0;
      width: 100%;
    }
  }
}

.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label {
  transform: none !important;
  font-size: 12px !important;
  top: 0;
  align-items: flex-start;
}
.withArrow {
  .form-floating {
    position: relative;
    &::before {
      background: url("../../assets/images/icon-select-arrow.svg");
      width: 16px;
      height: 16px;
      background-repeat: no-repeat;
      content: "";
      pointer-events: none;
      background-size: 100% 100%;
      background-position: center center;
      position: absolute;
      top: 27px;
      right: 15px;
      z-index: 2;
      background-color: white;
    }
  }
  .form-control {
    &.form-select {
      padding-bottom: 14px !important;
      padding-left: 20px !important;
      appearance: none;
      white-space: nowrap;
      text-overflow: ellipsis;
      padding-right: 32px !important;
      ~ label {
        font-size: 14px !important;
        align-items: center !important;
      }
      &.value-selected {
        ~ label {
          transform: none !important;
          font-size: 12px !important;
          align-items: flex-start !important;
        }
      }
    }
  }
}
.form-floating {
  select {
    padding-left: 0.75rem !important;
  }
}
.form-floating > .form-control:focus,
.form-floating > .form-control:not(:placeholder-shown) {
  padding-top: 36px;
  padding-bottom: 17px;
}

.errorMsgBox {
  display: flex;
  align-items: center;
  max-width: 400px;
  width: 100%;
  height: 40px;
  background: rgb(255 76 76 / 10%);
  border-radius: 5px;
  padding: 13px 20px 12px;
  font-style: normal;
  font-weight: 600;
  font-family: "Pretendard-SemiBold";
  font-size: 12px;
  line-height: 15px;
  color: #ff4c4c;
  margin-top: -15px;
  margin-bottom: 20px;
}

.SignUpAccount {
  margin-top: 20px;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  color: #52626c;
  .SignUpBtn {
    color: #25adfc;
    text-decoration: none;
    cursor: pointer;
  }
}
.customOptInput {
  .form-group {
    > div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-left: -8px;
      margin-right: -8px;
      div {
        width: 16.666667%;
        padding-left: 8px;
        padding-right: 8px;
        input {
          outline: 0px none;
          color: rgb(27 23 38 / 100%);
          background: #ffffff !important;
          border: 1px solid rgb(82 98 108 / 10%) !important;
          width: 100% !important;
          height: 70px;
          border-radius: 5px;
          &:focus {
            border: 1px solid #25adfc !important;
          }
          &::placeholder {
            color: rgb(27 23 38 / 40%);
          }
        }
      }
    }
  }
}
.backToHomeLink {
  margin: 20px 0 0;
  display: inline-block;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  text-decoration-line: underline;
  color: #25adfc;
  cursor: pointer;
}

.resend-wrap {
  margin-top: 20px;
  text-align: center;
  line-height: 17px;
  p {
    margin: 0 !important;
    line-height: 17px;
  }
  .resent-txt {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #606060;
  }
  .resendBtn {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-decoration-line: none;
    color: #25adfc;
    cursor: pointer;
    margin-left: 3px;
    &.disabled-btn {
      color: rgb(37 173 252 / 50%);
    }
  }
  .otpTImer {
    margin-left: 5px;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #25adfc;
  }
}
